import { DatePipe } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, TemplateRef, Pipe } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
// import { DomSanitizer } from 'POS-win32-ia32/resources/app/node_modules/@angular/platform-browser/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';

import { appConfig } from 'src/app/app.config';
import { Categories } from 'src/app/models/categories';
import { OrderItems } from 'src/app/models/order-items';
import { OrderTypes } from 'src/app/models/order-types';
import { Orders } from 'src/app/models/orders';
import { CMSService } from 'src/app/services/cms.service';
import { DataServiceService } from 'src/app/services/data-service.service';
import { IndexDbService } from 'src/app/services/index-db.service';
import { PassingDataService } from 'src/app/services/passing-data.service';
import { PosService } from 'src/app/services/pos.service';
// import { NgxIndexedDBService } from 'ngx-indexed-db';
import { WebSqlService } from 'src/app/services/web-sql.service';
import { DecodeHtmlEntities } from 'decode-html-entities';
import { isNumber } from 'ngx-bootstrap/chronos/utils/type-checks';
import { ThermalPrinterPlugin } from 'thermal-printer-cordova-plugin/src';
declare let ThermalPrinter: ThermalPrinterPlugin;
import { AppModule } from 'src/app/app.module';
import { TranslateService } from '@ngx-translate/core';
import { empty } from 'rxjs';
import { add } from 'ngx-bootstrap/chronos';
import { timer } from 'rxjs';
import { async } from '@angular/core/testing';
// import { ThisReceiver } from '@angular/compiler';
// import { threadId } from 'node:worker_threads';

const counter = timer(0, 1000);

@Pipe({ name: 'safe' })



@Component({
  selector: 'app-pos-dashboard',
  templateUrl: './pos-dashboard.page.html',
  styleUrls: ['./pos-dashboard.page.scss'],
})

export class PosDashboardPage implements OnInit {
  sureTemp: any;
  rider: any;
  reasonModel: any;
  currentDate: any;
  categories: Categories[] = [];
  orders: Orders[] = [];
  order_items: OrderItems[] = [];
  order_store_detail: any = '';
  selected_table: any = {};
  order_store_detail_data: any = '';
  orderTypes: OrderTypes[] = [];
  tenderTypes: any = [];
  cart: any = [];
  datasource: any = [];
  orderList: any = [];
  savedOrderList: any = [];
  runningOrderList: any = [];
  dispatchedOrderList: any = [];
  completeOrderList: any = [];
  cancelledOrderList: any = []
  orderTypeList = [];
  cartData: any = {};
  currentNumber = '0';
  textboxname: any;
  waitForSecondNumber = false;
  syncDisabled = false;
  riderList = [];
  taxList = [];
  addressList = [];
  tenderObj: any;

  ord_type: any;
  config: any = {
    tax: 5,
    delivery_charge: 0,
    disValue: 0
  }
  grandTotal: any = {
    sub_total: 0,
    tax_amt: 0,
    grand_total: 0,
    total_qty: 0
  };
  p: number = 1;
  p1: number = 1;
  p2: number = 1;
  p3: number = 1;
  p4: number = 1;
  p5: number = 1;
  p6: number = 1;
  date = new Date();
  totalItems: number;
  terminal_id: any;
  store_id: any;
  customer: any;
  cancel_btn: boolean = true;
  cancel_re_btn: boolean = false;
  recall_btn: boolean = true;
  print_btn: boolean = false;
  reject_btn: boolean = false;
  accept_btn: boolean = false;
  process_btn: boolean = false;
  completeBtn: boolean = false;
  assign_dirver_btn: boolean = false;
  completedTotalItem: number = 0;
  savedTotalItem: number = 0;
  cancelOrderItem: number = 0;
  pendingTotalItem: number = 0;
  acceptedTotalItem: number = 0;
  processingTotalItem: number = 0;
  deliveryTotalItem: number = 0
  reasonList = []
  currentUser: any = {}
  cnlOrder: any = {};
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  completeEndofDayBtn: boolean;
  user: any = {}
  tenderTypeId: any;
  clickedBtn: any;
  fiananceName: any;
  canOrder: any;
  activeTab;
  addnewreason: boolean = false;
  tempOrderList: any = [];
  isCollapse: boolean = false;
  filterobj: any = {};
  store_detail: any = {};
  current_date: any;
  cust: any = {};
  internetStatus: any = "Online";
  currency_symbol = appConfig.currency_symbol;
  sync_img = "assets/images/sync_white.png";
  ItemPerPage = 10;
  darkmode: boolean;
  platform: any;
  public myAngularxQrCode: string = null;
  imagestring: any = null;
  tempAdd: any = {}
  configDiscount: any = {
    tax: 0,
    delivery_charge: 0,
    disValue: 0,
    discount_data: {
      id: 0,
      discount_rate: 0,
      discount_type: 0
    },
    isDiscountApply: false
  }
  isPlatform: boolean = false;
  permissionList = []
  orderTypeData = [];
  temporderType = []
  filterCount: any;
  searchText: any;
  is_login_with_pin: any;
  storeReceiptData: any;
  storePosBehavior: any = {};
  defaultUser: any;
  tableStatusFilter: any = 3
  tables: any = [];

  constructor(private _router: Router,
    private _dataService: DataServiceService,
    private passData: PassingDataService,
    private _posService: PosService,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    private cmsService: CMSService,
    private modalService: BsModalService,
    private active_route: ActivatedRoute,
    public indexdb_service: IndexDbService,
    // private indexdbservice: NgxIndexedDBService,
    private toastr: ToastrService,
    private _datePipe: DatePipe,
    public sqlService: WebSqlService,
    public route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public appmodule: AppModule,
    public translate: TranslateService,
    public decodeHtmlEntities: DecodeHtmlEntities) {
    this.myAngularxQrCode = 'Your QR code data string';
  }

  ngOnInit(): void {

  }
  ionViewWillEnter() {
    this.darkmode = JSON.parse(localStorage.getItem('darkMode'))
    this.is_login_with_pin = localStorage.getItem('is_login_with_pin')
    console.log(this.is_login_with_pin)
    this.currency_symbol = localStorage.getItem('currency_symbol')
    this.storePosBehavior = JSON.parse(localStorage.getItem('storePosBehavior'))
    this.defaultUser = localStorage.getItem('defaultUser')
    this.passData.checkShare.subscribe(data => {
      var prvData = data;
      if (!this.isEmpty(prvData)) {
        this.internetStatus = prvData
      }
      // console.log(this.internetStatus)
    });
    if (this.internetStatus != "Offline") {
      // online
      this.sqlService.syncForOrders();
    }

    this.passData.checkNewOrder.subscribe(data => {
      var orderStatus = data;
      // console.log(this.activeTab)
      let order_status = 0;
      // debugger
      if (this.activeTab == 'saved') {
        order_status = 0;
      } else if (this.activeTab == 'pending') {
        order_status = 3
      } else if (this.activeTab == 'accepted') {
        order_status = 4
      } else if (this.activeTab == 'inprocess') {
        order_status = 6
      } else if (this.activeTab == 'indelivery') {
        order_status = 5
      } else if (this.activeTab == 'completed') {
        order_status = 1
      } else if (this.activeTab == 'cancel') {
        order_status = 2
      }
      if (orderStatus == true) {
        let senddata = {
          "page_no": this.p,
          "order_status": order_status
        }
        this.getOrdersfromWebSql(senddata)
      }
      // if (!this.isEmpty(prvData)) {
      //   this.internetStatus = prvData
      // }
    })

    this.clearData();
    this.clearFilter();
    this.getTenderDataFromWebSql();
    let frompage = localStorage.getItem('frmpage')
    let ordsts = 0

    if (frompage == 'dash' || frompage == 'pos') {
      ordsts = 0
      this.activeTab = 'saved';
      this.cancel_btn = true;
      this.cancel_re_btn = false;
      this.print_btn = false;
      this.reject_btn = false;
      this.accept_btn = false;
      this.recall_btn = true;
      this.completeBtn = false;
      this.assign_dirver_btn = false;
    } else {
      ordsts = 1
      this.activeTab = 'completed';
      this.cancel_btn = true;
      this.cancel_re_btn = true;
      this.print_btn = true;
      this.reject_btn = false;
      this.accept_btn = false;
      this.recall_btn = false;
      this.completeBtn = false;
      this.assign_dirver_btn = false;
    }




    localStorage.removeItem('selectedTender')
    this.active_route.queryParams.subscribe(params => {
      var paramsObj = params;
      // console.log(paramsObj)
      if (!this.isEmpty(paramsObj)) {
        this.completeEndofDayBtn = true;
      } else {
        this.completeEndofDayBtn = false;
      }
    })
    console.log(this.cartData)
    this.cartData.total_price = "0"
    this.cartData.total_tax = "0"
    this.cartData.grand_total = "0";
    this.cartData.total_discount = "0"
    let current_date = new Date()
    this.current_date = this._datePipe.transform(current_date, "dd-MM-yyyy hh:mm a")
    this.terminal_id = localStorage.getItem('terminal_id');
    this.store_detail = JSON.parse(localStorage.getItem('store_detail'));
    this.store_id = localStorage.getItem('store_id');
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    let senddata = {
      "page_no": this.p,
      "order_status": ordsts
    }

    this.getOrdersfromWebSql(senddata)

    let fromSendToKitchen = localStorage.getItem('fromSendToKitchen');

    localStorage.removeItem('fromSendToKitchen');
    if (fromSendToKitchen == '1') {
      debugger;
      localStorage.removeItem("fromTable")
      localStorage.removeItem("selectedTable")

      this.tabClicked('inprocess')
    }

    // let onlineSendData = {
    //   "page_no": this.p,
    //   "order_status": 3,
    //   "terminal_id": this.terminal_id
    // }
    // this.getOrderData(onlineSendData)
  }


  transform(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
  getTenderDataByidFromWebSql(tender_profile_id) {
    this.sqlService.dbInstance.executeSql(`SELECT * FROM tender_profiles_store`, []).then(async (data) => {
      //  this.orderTypes = data.rows;
      let list = []
      for (let i = 0; i < data.rows.length; i++) {
        list.push(data.rows.item(i));
      }

      list.forEach(element => {
        if (element.database_id == parseInt(tender_profile_id)) {
          this.tenderObj = element
          this.ord_type = element.name
          localStorage.setItem("selectedTender", JSON.stringify(this.tenderObj))
        }
      });
      this.orderTypeList = list;
    });
  }
  checkinCheckoutClick(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-dialog-centered modal-lg');
    this.onfocus("username")
  }
  async checkout(table) {
    this.user.store_id = this.store_id;
    let checkinUser = JSON.parse(localStorage.getItem('checkinUserList'));
    var checkLogin = 0
    var localuser
    if (checkinUser) {
      if (this.is_login_with_pin == 0) {
         localuser = checkinUser.find(x => Number(x.userDetail.username) === Number(this.user.username));
        if ((localuser) && Number(localuser.userDetail.username) === Number(this.user.username) && localuser.userDetail.password === this.user.password
          && localuser.userDetail.store_id === this.user.store_id) {
          checkLogin = 1
        }
        //  else {
        //   this.toastr.error(this.translate.instant("Username or Password not match"))
        // }
      } else {
         localuser = checkinUser.find(x => Number(x.userDetail.username) === Number(this.user.username));
        checkLogin = 1
      }
      if (localuser) {

        if (checkLogin == 1) {
          localStorage.setItem('currentUser', JSON.stringify(localuser))
          localStorage.setItem('token', JSON.stringify(localuser.user_token));
          this.cartData = table.order
          this.getTenderDataByidFromWebSql(this.cartData.tender_profile_id)
          localStorage.removeItem('rawCartItems');
          localStorage.removeItem('rawAdjustedData');
          localStorage.removeItem('rawcartCustomer');
          localStorage.removeItem('rawcartDiscount');
          localStorage.removeItem('mycart');
          this.cust = {
            id: this.cartData.customer_id,
            name: this.cartData.customer_name.split(' ')[0],
            lname: this.cartData.customer_name.split(' ')[1],
            phone: this.cartData.customer_phone
          }
          localStorage.setItem("rawcartCustomer", JSON.stringify(this.cust));

          let discount = {
            discount_id: this.cartData.discount_id,
            discount_rate: this.cartData.discount_rate,
            discount_type: this.cartData.discount_type,
          }
          localStorage.setItem("rawcartDiscount", JSON.stringify(discount));


          this.configDiscount.discount_data.id = discount.discount_id;
          this.configDiscount.discount_data.discount_rate = discount.discount_rate
          this.configDiscount.discount_data.discount_type = discount.discount_type;
          this.configDiscount.isDiscountApply = true;


          let raw_order_details = this.cartData.order_details;
          if (raw_order_details?.length > 0) {
            raw_order_details.forEach((item, index) => {
              item.database_id = item.pricelookup_id;
              item.index = index;
            });
          }

          let order_items = raw_order_details;
          order_items.forEach(element => {
            // debugger
            // console.log('element  cal_price ', element.cal_price);
            // console.log('order_items element', element);

            //Add AddsOn
            let addAddOnsvalid = this._dataService.IsJsonString(element['add_addons_detail']);
            if (addAddOnsvalid) {
              element['add_addons_detail'] = JSON.parse(element['add_addons_detail']);
            }
            //END Add AddsOn

            //Remove AddsOn
            let removeAddonsValid = this._dataService.IsJsonString(element['remove_addons_detail']);
            if (removeAddonsValid) {
              element['remove_addons_detail'] = JSON.parse(element['remove_addons_detail']);
            }
            //Remove AddsOn

          });

          raw_order_details = await this._dataService.getCartData(order_items);


          this.cartData.order_details = raw_order_details;

          if (this.cartData.order_details?.length > 0) {
            await this.cartDataFetch(this.cartData.order_details);
          }
          this.cartData.order_id = this.cartData.local_transaction_no;

          this.cart = this.cartData.order_details

          this.finalCalculationForCheckout();

          this.cart.forEach(element => {
            element.selectionColor = false;
          });

          let data = {
            id: this.cartData.id,
            cart: this.cart,
            customer: this.cust,
            tenderType: this.tenderObj,
            grandTotal: this.grandTotal,
            old_order_id: this.cartData.adujusted_order_id ? this.cartData.adujusted_order_id : 0,
            saved_order_id: this.cartData.order_id ? this.cartData.order_id : 0,
            adjusted_reason: this.cartData.adjustReason ? this.cartData.adjustReason : '',
            store_order_sequence_id: this.cartData.store_order_sequence_id
          };
          localStorage.setItem("mycart", JSON.stringify(data))
          this.modalService.hide()
          this._router.navigate(['/checkout'], { queryParams: { checkoutType: 'PD' } });
        } else {
          this.toastr.error(this.translate.instant("Username or Password not match"))
        }
      } else {
        this.toastr.error(this.translate.instant("You haven't clock in. Please clock in first"))
      }
    }

  }
  /*async checkout(table) {

    this.user.store_id = this.store_id;
    let checkinUser = JSON.parse(localStorage.getItem('checkinUserList'));
    if (checkinUser) {
      var localuser = checkinUser.find(x => Number(x.userDetail.username) === Number(this.user.username));
      if (localuser) {
        if (Number(localuser.userDetail.username) === Number(this.user.username) && localuser.userDetail.password === this.user.password
          && localuser.userDetail.store_id === this.user.store_id) {

          localStorage.setItem('currentUser', JSON.stringify(localuser))
          localStorage.setItem('token', JSON.stringify(localuser.user_token));


          this.cartData = table.order
          this.getTenderDataByidFromWebSql(this.cartData.tender_profile_id)
          localStorage.removeItem('rawCartItems');
          localStorage.removeItem('rawAdjustedData');
          localStorage.removeItem('rawcartCustomer');
          localStorage.removeItem('rawcartDiscount');
          localStorage.removeItem('mycart');
          this.cust = {
            id: this.cartData.customer_id,
            name: this.cartData.customer_name.split(' ')[0],
            lname: this.cartData.customer_name.split(' ')[1],
            phone: this.cartData.customer_phone
          }
          localStorage.setItem("rawcartCustomer", JSON.stringify(this.cust));
          console.log("this.cartData",this.cartData)
          let discount = {
            discount_id: this.cartData.discount_id,
            discount_rate: this.cartData.discount_rate,
            discount_type: this.cartData.discount_type,
          }
          localStorage.setItem("rawcartDiscount", JSON.stringify(discount));

          this.configDiscount.discount_data.id = discount.discount_id;
          this.configDiscount.discount_data.discount_rate = discount.discount_rate
          this.configDiscount.discount_data.discount_type = discount.discount_type;
          this.configDiscount.isDiscountApply = true;

          let raw_order_details = this.cartData.order_details;
          if (raw_order_details?.length > 0) {
            raw_order_details.forEach((item, index) => {
              item.database_id = item.pricelookup_id;
              item.index = index;
            });
          }

          let order_items = raw_order_details;
          order_items.forEach(element => {
            // debugger
            // console.log('element  cal_price ', element.cal_price);
            // console.log('order_items element', element);

            //Add AddsOn
            let addAddOnsvalid = this._dataService.IsJsonString(element['add_addons_detail']);
            if (addAddOnsvalid) {
              element['add_addons_detail'] = JSON.parse(element['add_addons_detail']);
            }
            //END Add AddsOn

            //Remove AddsOn
            let removeAddonsValid = this._dataService.IsJsonString(element['remove_addons_detail']);
            if (removeAddonsValid) {
              element['remove_addons_detail'] = JSON.parse(element['remove_addons_detail']);
            }
            //Remove AddsOn

          });



          if (this.cartData.order_details?.length > 0) {
            await this.cartDataFetch(this.cartData.order_details);
          }

          raw_order_details = await this._dataService.getCartData(order_items);

          this.cartData.order_details = raw_order_details;


          localStorage.setItem("rawCartItems", JSON.stringify(raw_order_details));

          this.cartData.order_id = this.cartData.local_transaction_no;
          // console.log(this.cartData);

          localStorage.setItem("rawCartItems", JSON.stringify(this.cartData.order_details))


          this.cart = this.cartData.order_details
          this.finalCalculationForCheckout();

          this.cart.forEach(element => {
            console.log('element', element)
            element.selectionColor = false;
          });

          let data = {
            id: this.cartData.id,
            cart: this.cart,
            customer: this.cust,
            tenderType: this.tenderObj,
            grandTotal: this.grandTotal,
            old_order_id: this.cartData.adujusted_order_id ? this.cartData.adujusted_order_id : 0,
            saved_order_id: this.cartData.order_id ? this.cartData.order_id : 0,
            adjusted_reason: this.cartData.adjustReason ? this.cartData.adjustReason : '',
            store_order_sequence_id: this.cartData.store_order_sequence_id
          };
          console.log("datapos",data)
          localStorage.setItem("mycart", JSON.stringify(data))
          this.modalService.hide()
          this._router.navigate(['/checkout'], { queryParams: { checkoutType: 'PD' } });
        } else {
          this.toastr.error(this.translate.instant("Username or Password not match"))
        }
      } else {
        this.toastr.error(this.translate.instant("You haven't clock in. Please clock in first"))
      }
    }

  } */

  checkinRecallClick(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-dialog-centered modal-lg');
    this.onfocus("username")
  }

  async recall(table) {
    this.user.store_id = this.store_id;
    let checkinUser = JSON.parse(localStorage.getItem('checkinUserList'));
    var checkLogin = 0
    var localuser
    if (checkinUser) {
      if (this.is_login_with_pin == 0) {
         localuser = checkinUser.find(x => Number(x.userDetail.username) === Number(this.user.username));
        if ((localuser) && Number(localuser.userDetail.username) === Number(this.user.username) && localuser.userDetail.password === this.user.password
          && localuser.userDetail.store_id === this.user.store_id) {
          checkLogin = 1
        } 
        // else {
        //   this.toastr.error(this.translate.instant("Username or Password not match"))
        // }
      } else {
         localuser = checkinUser.find(x => Number(x.userDetail.username) === Number(this.user.username));
        checkLogin = 1
      }
      if (localuser) {
        if (checkLogin == 1) {
          localStorage.setItem('currentUser', JSON.stringify(localuser))
          localStorage.setItem('token', JSON.stringify(localuser.user_token));

          console.log("tablepos", table);
          this.cartData = table.order
          localStorage.removeItem('rawCartItems');
          localStorage.removeItem('rawAdjustedData');
          localStorage.removeItem('rawcartCustomer');
          localStorage.removeItem('rawcartDiscount');
          localStorage.removeItem('mycart');
          this.cust = {
            id: this.cartData.customer_id,
            name: this.cartData.customer_name.split(' ')[0],
            lname: this.cartData.customer_name.split(' ')[1],
            phone: this.cartData.customer_phone
          }
          localStorage.setItem("rawcartCustomer", JSON.stringify(this.cust));

          let discount = {
            discount_id: this.cartData.discount_id,
            discount_rate: this.cartData.discount_rate,
            discount_type: this.cartData.discount_type,
          }
          localStorage.setItem("rawcartDiscount", JSON.stringify(discount));

          this.configDiscount.discount_data.id = discount.discount_id;
          this.configDiscount.discount_data.discount_rate = discount.discount_rate
          this.configDiscount.discount_data.discount_type = discount.discount_type;
          this.configDiscount.isDiscountApply = true;

          let raw_order_details = this.cartData.order_details;
          if (raw_order_details?.length > 0) {
            raw_order_details.forEach((item, index) => {
              item.database_id = item.pricelookup_id;
              item.index = index;
            });
          }

          let order_items = raw_order_details;
          order_items.forEach(element => {
            // debugger
            // console.log('element  cal_price ', element.cal_price);
            // console.log('order_items element', element);

            //Add AddsOn
            let addAddOnsvalid = this._dataService.IsJsonString(element['add_addons_detail']);
            if (addAddOnsvalid) {
              element['add_addons_detail'] = JSON.parse(element['add_addons_detail']);
            }
            //END Add AddsOn

            //Remove AddsOn
            let removeAddonsValid = this._dataService.IsJsonString(element['remove_addons_detail']);
            if (removeAddonsValid) {
              element['remove_addons_detail'] = JSON.parse(element['remove_addons_detail']);
            }
            //Remove AddsOn

          });
          // console.log('order_items', order_items);
          // order.order_details = order_items;

          // this.cartData.customer_name = order.order_customer.name + " " + order.order_customer.lname;
          // this.cartData.phone = order.order_customer.phone;
          raw_order_details = this._dataService.getCartData(order_items);

          this.cartData.order_details = raw_order_details;


          localStorage.setItem("rawCartItems", JSON.stringify(raw_order_details));

          this.cartData.order_id = this.cartData.local_transaction_no;
          // console.log(this.cartData);

          localStorage.setItem("rawCartItems", JSON.stringify(this.cartData.order_details))


          // this._router.navigate(['/pos'], { queryParams: { order_type: this.tenderTypeId, buttonType: 'RC' } })
          let sql = `SELECT database_id FROM tender_profiles_store where is_table_allow = 1`;
          await this.sqlService.dbInstance.executeSql(sql, []).then((data) => {
            this.passData.tableNewOrder(table);
            this.tenderTypeId = data.rows.item(0).database_id;
            this.passData.recallCartData({})
            this.modalService.hide()
            this._router.navigate(['/pos'], { queryParams: { order_type: this.tenderTypeId, buttonType: 'RC' } })

          }).catch((err) => {
            this.toastr.error(this.translate.instant("oops something went wrong"), 'warning');
            this.spinner.hide();
            console.log(err);
          });

          // this.createMycartIndexdb();
          // this.passData.recallCartData(this.cartData)
          // this._router.navigate(['/pos'], { queryParams: { order_type: this.tenderTypeId, buttonType: 'RC' } })
        } else {
          this.toastr.error(this.translate.instant("Username or Password not match"))
        }
      } else {
        this.toastr.error(this.translate.instant("You haven't clock in. Please clock in first"))
      }
    }
  }

  cartDataFetch(cartProducts) {
    console.log("cartProducts", cartProducts)
    return new Promise(async (resolve, reject) => {
      let newArray = [];

      for (let i = 0; i < cartProducts.length; i++) {
        if (cartProducts[i]?.add_addons_detail?.length > 0) {
          cartProducts[i].addOnItemAry = cartProducts[i].add_addons_detail;
        }
        if (cartProducts[i]?.remove_addons_detail?.length > 0) {
          cartProducts[i].removeItemAry = cartProducts[i].remove_addons_detail;
        }
        let pricelookup_id = cartProducts[i].pricelookup_id;

        //Take Extra data from local DB

        let data = await this.getMenuItemFromDbId(pricelookup_id);

        //END Take Extra data from local DB
        cartProducts[i].add_groups_addons = data['add_groups_addons'];
        cartProducts[i].background_color = data['background_color'];
        cartProducts[i].background_image = data['background_image'];
        cartProducts[i].description = data['description'];
        cartProducts[i].extra_operation = data['extra_operation'];
        cartProducts[i].name = data['name'];
        cartProducts[i].remove_groups_addons = data['remove_groups_addons'];
        cartProducts[i].standard_price = data['standard_price'];
        cartProducts[i].store_categories = data['store_categories'];
        cartProducts[i].store_id = data['store_id'];
        cartProducts[i].store_ingredients = data['store_ingredients'];
        cartProducts[i].store_tax = data['store_tax'];
        cartProducts[i].tax_include = data['tax_include'];
        cartProducts[i].tax_include_price = data['tax_include_price'];
        cartProducts[i].tender_profiles = data['tender_profiles'];

        newArray.push(cartProducts[i])
      }
      console.log("newArray", newArray)
      localStorage.setItem("rawCartItems", JSON.stringify(newArray));

      resolve('');
    })


  }

  async getMenuItemFromDbId(pricelookup_id) {
    return new Promise((resolve, reject) => {
      this.sqlService.dbInstance.executeSql(`SELECT * FROM plu_menu_item where database_id = ` + pricelookup_id, []).then(async (data) => {
        let localdata = data.rows.item(0);
        resolve(localdata);
        // element.add_groups_addons = localdata['add_groups_addons'];
        // element.background_color = localdata['background_color'];
        // element.background_image = localdata['background_image'];
        // element.description = localdata['description'];
        // element.extra_operation = localdata['extra_operation'];
        // element.name = localdata['name'];
        // element.remove_groups_addons = localdata['remove_groups_addons'];
        // element.standard_price = localdata['standard_price'];
        // element.store_categories = localdata['store_categories'];
        //  element.store_id = localdata['store_id'];
        // element.store_ingredients = localdata['store_ingredients'];
        // element.store_tax = localdata['store_tax'];
        // element.tax_include = localdata['tax_include'];
        // element.tax_include_price = localdata['tax_include_price'];
        // element.tender_profiles = localdata['tender_profiles'];
        // alert(2)
      })
    })
  }

  async getOrdersfromWebSql(filter) {
    this.sqlService.getOrdersfromWebSql(filter)
      .then(res => {
        console.log(res)
        // this.orderList = res['orders'];

        let list = res['orders']

        const repeat_local_id = [];
        const local_id_array = [];
        const lookup = list.reduce((a, e) => {

          if (local_id_array.indexOf(e.local_transaction_no) != -1) {
            repeat_local_id.push(e.local_transaction_no);
          }
          local_id_array.push(e.local_transaction_no);
          // if(a[e.local_transaction_no] == a[e.local_transaction_no]){
          //   repeat_local_id.push(e.local_transaction_no);
          // }
          a[e.local_transaction_no] = ++a[e.local_transaction_no] || 0;
          //console.log(a)
          return a;

        }, {});

        // console.log(repeat_local_id)
        // let duplicate  = list.filter(e => lookup[e.local_transaction_no]);

        // duplicate.forEach(element => {
        //   element.isDuplicate = true
        // });
        list.forEach(element => {
          element.customer_name = element.customer_name.replace(/  +/g, ' ');
          var local_transaction_no = element.local_transaction_no;
          if (repeat_local_id.indexOf(local_transaction_no) != -1) {
            element.isDuplicate = true
          } else {
            element.isDuplicate = false
          }
        });

        this.orderList = list;
        console.log('this.orderList', this.orderList)
        this.tempOrderList = this.orderList;
        let totalrows = res['totals'];
        this.totalItems = res['totals'];
        // debugger
        this.savedTotalItem = totalrows.saved;
        this.completedTotalItem = totalrows.comp;
        this.cancelOrderItem = totalrows.cancel;
        this.pendingTotalItem = totalrows.pending;
        this.acceptedTotalItem = totalrows.accepted;
        this.processingTotalItem = totalrows.processing;
        this.deliveryTotalItem = totalrows.delivery;
      });

  }

  async getOrdersByTableidfromWebSql(table_id) {
    return new Promise(resolve => {
      this.sqlService.getOrdersByTableidfromWebSql(table_id)
        .then(res => {
          res['customer_name'] = res['customer_name'].replace(/  +/g, ' ');
          resolve(res);
        });
    })
  }
  htmlconverter(htmlstring) {
    return this.decodeHtmlEntities.transform(htmlstring, [])
  }

  getOrderData(senddata) {
    // this.spinner.show()
    // var obj = {
    //   "page_no": this.p,
    //   "order_status": 0
    // }
    this._posService.getOrderData(senddata)
      .subscribe(
        res => {
          if (res.status === 1) {
            // this.orderList = res.data.orders;
            // this.orderList = [];
            let orders = res.data.orders;
            // console.log(orders)

            // this.spinner.hide()
          } else {
            this.orderList = []

            // this.spinner.hide()
          }
        },
        err => {
          console.log("error", err);
        })

  }

  // printReciept() {
  //   let printContents, popupWin;
  //   printContents = document.getElementById('invoice-POS-detail').innerHTML;
  //   popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
  //   popupWin.document.open();
  //   popupWin.document.write(`
  //       <html>
  //         <head>
  //           <title>Print tab</title>
  //           <link rel="stylesheet" type="text/css" href="assets/styles/print.css">
  //           <style>
  //           //........Customized style.......
  //           </style>
  //         </head>
  //     <body onload="window.print();window.close()">${printContents}</body>
  //       </html>`
  //   );
  //   popupWin.document.close();
  // }


  /* addOnList(addOnItemAry) {
   let addOnString = ""
   for (let i = 0; i < addOnItemAry; i++) {
     addOnString = addOnString + "[L]<b> + " + addOnItemAry[i].name + "</b>[R]<b>" + this.store_detail.currency_symbol + " " + addOnItemAry[i].price + "</b>\n"
   }
   return addOnString;

 }

 removeList(addOnItemAry) {
   let rmvString = ""
   for (let i = 0; i < addOnItemAry; i++) {
     rmvString = rmvString + "[L]<b> + " + addOnItemAry[i].name + "</b>[R]<b>" + this.currency_symbol + " " + addOnItemAry[i].price + "</b>\n"
   }
   return rmvString;
 }

 itemList() {
   let tmpString = ""
   for (let i = 0; i < this.cartData.order_details.length; i++) {
     if (this.cartData.order_details[i].addOnItemAry) {
       tmpString = tmpString + "[L]<b>" + this.cartData.order_details[i].pricelookup_qty + "   " + this.appmodule.truncateText(this.cartData.order_details[i].pricelookup_name, 28) + "</b>[R]" + this.currency_symbol + " " + this.cartData.order_details[i].cal_price + "\n" + this.addOnList(this.cartData.order_details[i].addOnItemAry)

     } else if (this.cartData.order_details[i].removeItemAry) {
       tmpString = tmpString + "[L]<b>" + this.cartData.order_details[i].pricelookup_qty + "   " + this.appmodule.truncateText(this.cartData.order_details[i].pricelookup_name, 28) + "</b>[R]" + this.currency_symbol + " " + this.cartData.order_details[i].cal_price + "\n" + this.removeList(this.cartData.order_details[i].removeItemAry)
     }
     else {
       tmpString = tmpString + "[L]<b>" + this.cartData.order_details[i].pricelookup_qty + "   " + this.appmodule.truncateText(this.cartData.order_details[i].pricelookup_name, 28) + "</b>[R]" + this.currency_symbol + " " + this.cartData.order_details[i].cal_price + "</b>\n"
     }

   }
   return tmpString
 }

 taxDataList() {
   var itemsText = '';
   this.taxList.forEach(async tax => {
     itemsText = itemsText + "[L]" + tax.name + " ( " + tax.tax_value + " ) [R]" + this.currency_symbol + " " + tax.amt + "\n"
   });
   return itemsText
 }

 noteData() {
   let staff_note_text = '';
   if (this.cartData.staff_note && this.cartData.staff_note != 'null') {
     staff_note_text = staff_note_text + "[L]<b>" + this.translate.instant('Note') + " :</b>" + this.cartData.staff_note + "\n"
   }
   return staff_note_text
 }

 cashChangeData() {
   let tempString = '';
   if (this.cartData.cash_change != 0) {
     tempString = tempString + "[L]" + this.translate.instant('Change') + this.cartData.cash_change + "[R]" + "" + "\n"
   }
   return tempString
 }

 receiptHeaderText() {
   let receipt_header_text = '';
   if(this.store_detail.receipt_header){
     receipt_header_text = receipt_header_text + "[L]" + this.appmodule.stripHtml(this.htmlconverter(this.store_detail.receipt_header)) + "\n"
   }
   return receipt_header_text
 }

 receiptFooterText(){
   var receipt_footer_text = '';
   if(this.store_detail.receipt_footer){
     receipt_footer_text = receipt_footer_text + "[L]" + this.appmodule.stripHtml(this.htmlconverter(this.store_detail.receipt_footer))+"\n"
   }
   return receipt_footer_text
 } */

  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }


  generteQrForOrderdetails() {
    var date = this._datePipe.transform(new Date(), "yyyy-MM-dd hh:mm:ss")
    return btoa("##" + this.store_detail.name + "##" + this.store_detail.gst_number + "##" + date + "##" + this.grandTotal.grand_total + "##" + this.grandTotal.tax_amt)
  }

  async printReciept() {
    var platform = localStorage.getItem('platform')
    if (platform == 'android') {
      await this.sqlService.dbInstance.executeSql(`SELECT * FROM store_printer_configurations WHERE type = 1 AND status = 1`, [])
        .then(async (customPrinterData) => {
          console.log(customPrinterData.rows.item(0));
          let customPrinterRowData = customPrinterData.rows.item(0);

          let printer_id = customPrinterRowData.ip_address;
          let is_ltr = customPrinterRowData.is_ltr;
          let port = customPrinterRowData.port;

          var items_text = '';
          var print_text = '';


          // await this.getBase64ImageFromUrl(this.store_detail.logo)
          //   .then(result => this.imagestring = result)
          //   .catch(err => console.error(err));

          if (is_ltr == 1) {
            var items_text = '';
            this.cartData.order_details.forEach(async item => {
              items_text += "[L]<b>" + item.pricelookup_qty + "   " + this.appmodule.truncateText(item.pricelookup_name, 28) + "</b>[R]" + this.currency_symbol + " " + item.cal_price + "\n"
              if (item?.addOnItemAry) {
                item?.addOnItemAry.forEach(async addon => {
                  items_text += "[L]      <b>+ </b>" + this.appmodule.truncateText(addon.name, 28) + " (" + this.currency_symbol + " " + addon.price + ")\n"
                });
              }
              if (item?.removeItemAry) {
                item?.removeItemAry.forEach(async remove => {
                  items_text += "[L]      <b>- </b>" + this.appmodule.truncateText(remove.name, 32) + "\n"
                });
              }
            });
            // debugger
            var tax_text = '';
            this.taxList.forEach(async tax => {
              tax_text += "[L]" + tax.name + " ( " + tax.tax_value + " ) [R]" + this.currency_symbol + " " + tax.amt + "\n"
            });

            // var address_list = '';
            // this.addressList.forEach(async add => {
            //   address_list += "[L]"+this.translate.instant('Address') + ": "+ add.house_no + " " + add.society_name + " " +add.city +" " +add.state +" " +add.pincode + "\n"
            // });
            var address_list = '';
            if (this.tempAdd != 0) {
              address_list += "[L]" + this.translate.instant('Address') + ": " + this.tempAdd + "\n"
            }

            var cashChange_text = '';
            if (this.cartData.cash_change != 0) {
              cashChange_text = "[L]" + this.translate.instant('Change') + ": " + this.cartData.cash_change + "\n"
            }

            var custome_text = "[L]<b>" + this.translate.instant('Name') + ": </b>" + this.appmodule.truncateText(this.cartData.customer_name, 16) + " [R] <b>" + this.translate.instant('Phone') + ": </b>" + this.cartData.customer_phone + "\n"


            var staff_note_text = '';
            if (this.cartData.staff_note && (this.cartData.staff_note != "null")) {
              staff_note_text = "[L]<b>" + this.translate.instant('Note') + " :</b>" + this.cartData.staff_note + "\n"
            }

            var store_name = '';
            if (this.checkStoreTemplatePermission('invoice_store_name') == true) {
              store_name = "[C]<font size='big'>" + this.store_detail.name + "</font>\n"
            }

            var store_address = '';
            if (this.checkStoreTemplatePermission('invoice_store_address') == true) {
              store_address = "[C]" + this.store_detail.address + "\n"
            }

            var receipt_header_text = '';
            if (this.store_detail.receipt_header && this.checkStoreTemplatePermissionGetValue('invoice_additional_info_header_note') == true) {
              receipt_header_text = "[L]" + this.appmodule.stripHtml(this.htmlconverter(this.store_detail.receipt_header)) + "\n"
            }
            var receipt_footer_text = '';
            if (this.store_detail.receipt_footer && this.checkStoreTemplatePermissionGetValue('invoice_additional_info_footer_note') == true) {
              receipt_footer_text = "[L]" + this.appmodule.stripHtml(this.htmlconverter(this.store_detail.receipt_footer)) + "\n"
            }


            var qr_text = '';
            // if(this.store_detail.address == ""){
            qr_text = "[C]<qrcode size='25'>" + this.generteQrForOrderdetails() + "</qrcode>";
            //}

            print_text =
              store_name +
              "[L]\n" +
              store_address +
              receipt_header_text +
              "[L]\n" +
              "[C] <b>** " + this.translate.instant('Tran No') + ". #" + this.cartData.local_transaction_no + " | " + this.translate.instant('Order No') + ". " + this.cartData.store_order_sequence_id + " **</b> " + "\n" +
              "[L]\n" +
              "[L]<b>" + this.translate.instant('Store ID') + ":</b> #" + this.store_id + " [R]<b>" + this.translate.instant('Terminal') + ":</b> #" + this.terminal_id + "\n" +
              "[L]<b>" + this.translate.instant('Clerk') + ":</b> #" + this.appmodule.truncateText(this.currentUser.id, 15) + " [R]<b>" + this.translate.instant('Dt') + ": </b>" + this.current_date + "\n" +
              custome_text +
              "[L]<b>" + this.translate.instant('Profile') + ":</b> " + this.cartData.tender_profile_name + " [R]<b>" + this.translate.instant('Payment') + ": </b>" + this.cartData.tender_type_name + "\n" +
              address_list +
              staff_note_text +
              "[C]------------------------------------------------\n" +
              "[L]" + this.translate.instant('Qty') + " " + this.translate.instant('Item') + " [R] " + this.translate.instant('Price') + "\n" +
              "[C]<b>------------------------------------------------</b>" + "\n" +
              items_text +
              "[L]\n" +
              "[C]------------------------------------------------" + "\n" +
              "[L]" + this.translate.instant('Sub Total') + "[R]" + this.htmlconverter(this.currency_symbol) + this.cartData.total_price + "\n" +
              "[L]" + this.translate.instant('Round Off') + "[R]" + this.currency_symbol + this.cartData.round_off + "\n" +
              "[L]" + this.translate.instant('Discount') + "[R]" + this.currency_symbol + this.cartData.total_discount + "\n" +
              "[L]<b>" + this.translate.instant('Total Amount') + "</b>[R]<b>" + this.currency_symbol + this.cartData.grand_total + "</b>\n" +
              "[C]------------------------------------------------" + "\n" + tax_text + "\n" +
              cashChange_text +
              "[L]" + this.translate.instant('GST No') + " : " + this.store_detail.gst_number + "\n" +
              "[L]" + this.translate.instant('Phone No') + " : " + this.store_detail.phone + "\n" +
              receipt_footer_text +
              qr_text + "\n" +
              "[L]" + "   " + "\n\n" +
              "[L]" + "   " + "\n\n"

          } else {
            var items_text = '';
            this.cartData.order_details.forEach(async item => {
              items_text += "[L]<b>" + item.pricelookup_qty + "   " + this.appmodule.truncateText(item.pricelookup_name, 28) + "</b>[R]" + this.currency_symbol + " " + item.cal_price + "\n"
              if (item?.addOnItemAry) {
                item?.addOnItemAry.forEach(async addon => {
                  items_text += "[L]      <b>+ </b>" + this.appmodule.truncateText(addon.name, 28) + " (" + this.currency_symbol + " " + addon.price + ")\n"
                });
              }
              if (item?.removeItemAry) {
                item?.removeItemAry.forEach(async remove => {
                  items_text += "[L]      <b>- </b>" + this.appmodule.truncateText(remove.name, 32) + "\n"
                });
              }
            });

            var tax_text = '';
            this.taxList.forEach(async tax => {
              tax_text += "[L]" + tax.name + " ( " + tax.tax_value + " ) [R]" + this.currency_symbol + " " + tax.amt + "\n"
            });

            // var address_list = '';
            // this.addressList.forEach(async add => {
            //   address_list += "[L]"+this.translate.instant('Address') + ": "+ add.house_no + " " + add.society_name + " " +add.city +" " +add.state +" " +add.pincode + "\n"
            // });

            var address_list = '';
            if (this.tempAdd != 0) {
              address_list += "[L]" + this.translate.instant('Address') + ": " + this.tempAdd + "\n"
            }

            var cashChange_text = '';
            if (this.cartData.cash_change != 0) {
              cashChange_text = "[L]" + 'Change' + ": " + this.cartData.cash_change + "\n"
            }


            var custome_text = "[L]<b>" + this.translate.instant('Name') + ": </b>" + this.appmodule.truncateText(this.cartData.customer_name, 16) + " [R] <b>" + this.translate.instant('Phone') + ": </b>" + this.cartData.customer_phone + "\n"


            var staff_note_text = '';
            if (this.cartData.staff_note && (this.cartData.staff_note != "null")) {
              staff_note_text = "[L]<b>" + this.translate.instant('Note') + " :</b>" + this.cartData.staff_note + "\n"
            }

            var store_name = '';
            if (this.checkStoreTemplatePermission('invoice_store_name') == true) {
              store_name = "[C]<font size='big'>" + this.store_detail.name + "</font>\n"
            }

            var store_address = '';
            if (this.checkStoreTemplatePermission('invoice_store_address') == true) {
              store_address = "[C]" + this.store_detail.address + "\n"
            }


            var receipt_header_text = '';
            if (this.store_detail.receipt_header && this.checkStoreTemplatePermissionGetValue('invoice_additional_info_header_note') == true) {
              receipt_header_text = "[L]" + this.appmodule.stripHtml(this.htmlconverter(this.store_detail.receipt_header)) + "\n"
            }
            var receipt_footer_text = '';
            if (this.store_detail.receipt_footer && this.checkStoreTemplatePermissionGetValue('invoice_additional_info_footer_note') == true) {
              receipt_footer_text = "[L]" + this.appmodule.stripHtml(this.htmlconverter(this.store_detail.receipt_footer)) + "\n"
            }


            var qr_text = '';
            // if(this.store_detail.address == ""){
            qr_text = "[C]<qrcode size='25'>" + this.generteQrForOrderdetails() + "</qrcode>";
            //}

            print_text = store_name +
              "[L]\n" +
              store_address +
              receipt_header_text +
              "[L]\n" +
              "[C] <b>** " + this.translate.instant('Tran No') + ". #" + this.cartData.local_transaction_no + " | " + this.translate.instant('Order No') + ". " + this.cartData.store_order_sequence_id + " **</b> " + "\n" +
              "[L]\n" +
              "[L]<b>" + this.translate.instant('Store ID') + ":</b> #" + this.store_id + " [R]<b>" + this.translate.instant('Terminal') + ":</b> #" + this.terminal_id + "\n" +
              "[L]<b>" + this.translate.instant('Clerk') + ":</b> #" + this.appmodule.truncateText(this.currentUser.id, 15) + " [R]<b>" + this.translate.instant('Dt') + ": </b>" + this.current_date + "\n" +
              custome_text +
              "[L]<b>" + this.translate.instant('Profile') + ":</b> " + this.cartData.tender_profile_name + " [R]<b>" + this.translate.instant('Payment') + ": </b>" + this.cartData.tender_type_name + "\n" +
              address_list +
              staff_note_text +
              "[C]------------------------------------------------\n" +
              "[L]" + this.translate.instant('Qty') + " " + this.translate.instant('Item') + " [R] " + this.translate.instant('Price') + "\n" +
              "[C]<b>------------------------------------------------</b>" + "\n" +
              items_text +
              "[L]\n" +
              "[C]------------------------------------------------" + "\n" +
              "[L]" + this.translate.instant('Sub Total') + "[R]" + this.htmlconverter(this.currency_symbol) + this.cartData.total_price + "\n" +
              "[L]" + this.translate.instant('Round Off') + "[R]" + this.currency_symbol + this.cartData.round_off + "\n" +
              "[L]" + this.translate.instant('Discount') + "[R]" + this.currency_symbol + this.cartData.total_discount + "\n" +
              "[L]<b>" + this.translate.instant('Total Amount') + "</b>[R]<b>" + this.currency_symbol + this.cartData.grand_total + "</b>\n" +
              "[C]------------------------------------------------" + "\n" + tax_text + "\n" +
              cashChange_text +
              "[L]" + this.translate.instant('GST No') + " : " + this.store_detail.gst_number + "\n" +
              "[L]" + this.translate.instant('Phone No') + " : " + this.store_detail.phone + "\n" +
              receipt_footer_text +
              qr_text + "\n" +
              "[L]" + "   " + "\n\n" +
              "[L]" + "   " + "\n\n"

          }

          if (customPrinterRowData.connectivity == 0) {  //0 =TCP, 1 = Bluetooth, 2 = USB

            // if (this.imagestring) {
            //   ThermalPrinter.bitmapToHexadecimalString({
            //     type: 'tcp',
            //     address: printer_id,
            //     port: port,
            //     id: printer_id + port, // Use an unique identifier for each printer i. e. address:port or name
            //     base64: this.imagestring.replace(/^data:image\/(png|jpg|jpeg);base64,/, "")

            //   }, function (value1) {
            //     console.log(value1);
            //     ThermalPrinter.printFormattedText({
            //       type: 'tcp',
            //       address: printer_id,
            //       port: port,
            //       id: printer_id + port, // Use an unique identifier for each printer i. e. address:port or name
            //       text: "[C]<img>" + value1 + "</img>\n"
            //     }, function () {
            //       console.log('Image Print');
            //     }, function (error) {
            //       console.error('customPrint error logo  Using TSP / IP', error);
            //     });
            //   }, function (error) {
            //     console.error('customPrint error  logo  Using TSP / IP', error);
            //   });
            // }

            // setTimeout(() => {
            ThermalPrinter.printFormattedTextAndCut({
              type: 'tcp',
              address: printer_id,
              port: port,
              id: printer_id + port, // Use an unique identifier for each printer i. e. address:port or name
              mmFeedPaper: 100,
              text: print_text
            }, function () {
              console.log('customPrint Successfully printed2!  Using TSP / IP');
            }, function (error) {
              this.toastr.error(this.translate.instant("Unable to connect printer"), '');
              console.error('customPrint error  Using TSP / IP', error);
            });
            // }, 4000);


          } else if (customPrinterRowData.connectivity == 1) {


            // if (this.imagestring) {
            //   ThermalPrinter.bitmapToHexadecimalString({
            //     type: 'bluetooth',
            //     id: printer_id,
            //     base64: this.imagestring.replace(/^data:image\/(png|jpg|jpeg);base64,/, "")

            //   }, function (value1) {
            //     console.log(value1);
            //     ThermalPrinter.printFormattedText({
            //       type: 'bluetooth',
            //       id: printer_id,
            //       text: "[C]<img>" + value1 + "</img>\n"
            //     }, function () {
            //       console.log('Image Print');
            //     }, function (error) {
            //       console.error('customPrint error logo  Using Bluetooth', error);
            //     });
            //   }, function (error) {
            //     console.error('Printing error  logo  Using Bluetooth', error);
            //   });
            // }

            // setTimeout(() => {
            ThermalPrinter.printFormattedTextAndCut({
              type: 'bluetooth',
              id: printer_id,
              mmFeedPaper: 100,
              text: print_text
            }, function () {
              console.log('customPrint Successfully printed2!  Using Bluetooth');
            }, function (error) {
              this.toastr.error(this.translate.instant("Unable to connect printer"), '');
              console.error('customPrint error  Using Bluetooth', error);
            });
            // }, 4000);


          } else if (customPrinterRowData.connectivity == 2) {

            // if (this.imagestring) {
            //   ThermalPrinter.bitmapToHexadecimalString({
            //     type: 'usb',
            //     id: printer_id,
            //     base64: this.imagestring.replace(/^data:image\/(png|jpg|jpeg);base64,/, "")

            //   }, function (value1) {
            //     console.log(value1);
            //     ThermalPrinter.printFormattedText({
            //       type: 'usb',
            //       id: printer_id,
            //       text: "[C]<img>" + value1 + "</img>\n"
            //     }, function () {
            //       console.log('Image Print');
            //     }, function (error) {
            //       console.error('customPrint error logo  Using USB', error);
            //     });
            //   }, function (error) {
            //     console.error('Printing error  logo  Using USB', error);
            //   });
            // }

            // setTimeout(() => {
            ThermalPrinter.printFormattedTextAndCut({
              type: 'usb',
              id: printer_id,
              mmFeedPaper: 100,
              text: print_text
            }, function () {
              console.log('customPrint Successfully printed2!  Using USB');
            }, function (error) {
              this.toastr.error(this.translate.instant("Unable to connect printer"), '');
              console.error('customPrint error  Using USB', error);
            });
            // }, 4000);

          }

        }).catch((err) => {
        })


    } else {
      console.log('else')
      this.modalRef.hide()
      let printContents, popupWin;
      printContents = document.getElementById('invoice-POS-detail').innerHTML;
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
          <html>
            <head>
              <title>Print tab</title>
              <link rel="stylesheet" type="text/css" href="assets/styles/print.css">
              <style>
              //........Customized style.......
              </style>
            </head>
        <body onload="window.print();window.close()">${printContents}</body>
          </html>`
      );
      popupWin.document.close();
    }

  }

  showLeftSideBar(template: TemplateRef<any>) {
    if (this.internetStatus == "Online") {
      this.modalRef = this.modalService.show(template);
      this.modalRef.setClass('modal-filter-detail modal-md');
      // this.getRiderListFromWebsql()
    } else {
      this.modalRef = this.modalService.show(template);
      this.modalRef.setClass('modal-filter-detail modal-md');
      // this.toastr.warning(this.translate.instant('System is offline'))
    }
  }

  closeFilter() {
    this.isCollapse = false;
  }

  clearFilter() {
    this.filterCount = ''
    // this.filterobj.clerk_id = "";
    // this.filterobj.transaction_no = "";
    // this.filterobj.ord_type_id = ""
    this.filterobj = {}
    this.orderTypeData = []
    this.isCollapse = false;
    let orderstatus;
    if (this.activeTab == 'saved') {
      orderstatus = 0
    }
    if (this.activeTab == 'completed') {
      orderstatus = 1
    }
    if (this.activeTab == 'cancel') {
      orderstatus = 2
    }
    let senddata = {
      "terminal_id": this.terminal_id,
      "page_no": this.p,
      "order_status": orderstatus,
      // "clerk_id": this.filterobj.clerk_id,
      // "transaction_no": this.filterobj.transaction_no,
      // "tender_profile_id": this.filterobj.ord_type_id
    }

    this.getOrdersfromWebSql(senddata);
  }

  // filterData() {
  //   let orderstatus;
  //   let pageNo = 1;
  //   if (this.activeTab == 'saved') {
  //     orderstatus = 0
  //     this.p = pageNo
  //     // pageNo= this.p
  //   }

  //   if (this.activeTab == 'completed') {
  //     orderstatus = 1
  //     this.p1 = pageNo
  //     // pageNo = this.p1
  //     // this.ItemPerPage = 10;

  //   }
  //   if (this.activeTab == 'cancel') {
  //     orderstatus = 2
  //     this.p2 = pageNo
  //     // pageNo = this.p2
  //   }
  //   if (this.activeTab == 'pending') {
  //     orderstatus = 3
  //     this.p3 = pageNo
  //     // pageNo = this.p3
  //   }
  //   if (this.activeTab == 'accepted') {
  //     orderstatus = 4
  //     this.p4 = pageNo
  //     // pageNo = this.p4
  //   }
  //   if (this.activeTab == 'inprocess') {
  //     orderstatus = 6,
  //       this.p5 = pageNo
  //     // pageNo = this.p5
  //   }
  //   if (this.activeTab == 'indelivery') {
  //     orderstatus = 5,
  //       this.p6 = pageNo
  //     // pageNo = this.p6
  //   }
  //   let senddata = {
  //     "terminal_id": this.terminal_id,
  //     "page_no": pageNo,
  //     "order_status": orderstatus,
  //     "clerk_id": this.filterobj.clerk_id,
  //     "transaction_no": this.filterobj.transaction_no,
  //     "order_no": this.filterobj.database_id,
  //     "tender_profile_id": this.filterobj.ord_type_id,
  //     "mobile_no": this.filterobj.mobile_no,
  //     "name": this.filterobj.name
  //   }
  //   this.getOrdersfromWebSql(senddata);
  //   this.isCollapse = false;
  // }

  selectedTenderProfileType(orType) {
    this.temporderType = orType.database_id
    this.orderTypeData.push(this.temporderType);
    console.log(this.orderTypeData)
  }

  filterData() {

    let orderstatus;
    let pageNo = 1;

    if (this.activeTab == 'saved') {
      orderstatus = 0
      // this.orderTypeData.push(this.temporderType);
      this.p = pageNo
    }

    if (this.activeTab == 'completed') {
      orderstatus = 1
      // var temporderType = orType.database_id
      // this.orderTypeData.push(this.temporderType);
      this.p1 = pageNo
    }
    if (this.activeTab == 'cancel') {
      orderstatus = 2
      // var temporderType = orType.database_id
      // this.orderTypeData.push(this.temporderType);
      this.p2 = pageNo
    }
    if (this.activeTab == 'pending') {
      orderstatus = 3
      // var temporderType = orType.database_id
      // this.orderTypeData.push(this.temporderType);
      this.p3 = pageNo
    }
    if (this.activeTab == 'accepted') {
      orderstatus = 4
      // var temporderType = orType.database_id
      // this.orderTypeData.push(this.temporderType);
      this.p4 = pageNo
    }
    if (this.activeTab == 'inprocess') {
      orderstatus = 6
      // var temporderType = orType.database_id
      // this.orderTypeData.push(this.temporderType);
      this.p5 = pageNo
    }
    if (this.activeTab == 'indelivery') {
      orderstatus = 5
      // var temporderType = orType.database_id
      // this.orderTypeData.push(this.temporderType);
      this.p6 = pageNo
    }
    let senddata = {
      "terminal_id": this.terminal_id,
      "page_no": pageNo,
      "order_status": orderstatus,
      "clerk_id": this.filterobj.clerk_id,
      "transaction_no": this.filterobj.transaction_no,
      "order_no": this.filterobj.database_id,
      "tender_profile_id": this.filterobj.ord_type_id,
      "filter_tender_profile_id": this.orderTypeData,
      "mobile_no": this.filterobj.mobile_no,
      "name": this.filterobj.name
    }
    console.log(senddata)
    this.filterCount = 1
    this.getOrdersfromWebSql(senddata);
    this.isCollapse = false;
  }

  async saveNewReason() {

    let data = [this.currentUser.business_id, this.currentUser.state_id, `'` + this.cnlOrder.reason_title + `'`, '4', 0]

    await this.sqlService.dbInstance.executeSql(`INSERT INTO reasons(business_id, state_id ,title, type, isSync) VALUES (` + data + `)`, [])
      .then(async (data) => {
        // online offline
        if (this.internetStatus != "Offline") {
          // online
          this.toastr.success('Saved Successfully')
          this.sqlService.syncReasons();
        }
        // let filter = { order_status: 4 };
        this.getReasonsFromWebSql(4);
        this.addnewreason = false
        // isSync = 0 get all
        // api call one by one
        // update isSync = 1
        // sync
      }).catch((err) => {
        this.toastr.error(this.translate.instant("oops something went wrong"), 'warning');
        this.spinner.hide();
        console.log(err);
      })
  }

  // saveNewReason() {
  //   this.spinner.show();
  //   this.cnlOrder.type = 4;
  //   this.cmsService.newpaidOutReason(this.cnlOrder)
  //     .subscribe(
  //       res => {
  //         if (res.status == 1) {
  //           this.toastr.success('Success', res.message)
  //           this.spinner.hide();
  //           this.addnewreason = false;
  //           this.getReasons(4);
  //         } else {
  //           this.toastr.error('warning', res.message)
  //           this.spinner.hide();
  //         }
  //       },
  //       error => {
  //         console.log(error);
  //         this.spinner.hide();
  //       });
  // }

  getTenderDataFromWebSql() {
    this.sqlService.dbInstance.executeSql(`SELECT * FROM tender_profiles_store`, []).then(async (data) => {

      let list = []
      for (let i = 0; i < data.rows.length; i++) {
        list.push(data.rows.item(i));
      }

      this.orderTypes = list



    });

  }

  // getTenderDataFromIndexdb() {
  //   this.indexdbservice.getAll('tenderprofiles').toPromise()
  //     .then(
  //       res => {
  //         this.orderTypes = res[0];
  //       },
  //       error => {
  //         console.log(error);
  //       }
  //     );
  // }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  tabClicked(tabName) {
    //
    this.orderList = []
    this.order_items = [];
    this.cartData = {};
    this.cartData.total_price = 0;
    this.cartData.total_tax = 0;
    this.cartData.grand_total = 0;
    this.cartData.total_discount = 0;
    if (tabName === 'saved') {
      this.activeTab = "saved"
      this.cancel_btn = true;
      this.cancel_re_btn = false;
      this.recall_btn = true;
      this.print_btn = false;
      this.accept_btn = false;
      this.reject_btn = false;
      this.completeBtn = false;
      this.process_btn = false;
      this.assign_dirver_btn = false;
      this.p = 1;
      let senddata = {
        "page_no": this.p,
        "order_status": 0,
        "terminal_id": this.terminal_id,
        "clerk_id": this.filterobj.clerk_id,
        "transaction_no": this.filterobj.transaction_no,
        "order_no": this.filterobj.database_id,
        "tender_profile_id": this.filterobj.ord_type_id,
        "filter_tender_profile_id": this.orderTypeData,
        "mobile_no": this.filterobj.mobile_no,
        "name": this.filterobj.name
      }
      this.getOrdersfromWebSql(senddata);
    }
    if (tabName === 'completed') {
      this.activeTab = "completed"
      this.cancel_btn = true;
      this.cancel_re_btn = true;
      this.recall_btn = false;
      this.print_btn = true;
      this.accept_btn = false;
      this.reject_btn = false;
      this.process_btn = false;
      this.completeBtn = false;
      this.assign_dirver_btn = false;
      this.p1 = 1
      this.ItemPerPage = 10
      let senddata = {
        "page_no": this.p1,
        "order_status": 1,
        "terminal_id": this.terminal_id,
        "clerk_id": this.filterobj.clerk_id,
        "transaction_no": this.filterobj.transaction_no,
        "order_no": this.filterobj.database_id,
        "tender_profile_id": this.filterobj.ord_type_id,
        "filter_tender_profile_id": this.orderTypeData,
        "mobile_no": this.filterobj.mobile_no,
        "name": this.filterobj.name
      }
      this.getOrdersfromWebSql(senddata)
    }
    if (tabName === 'cancel') {

      this.activeTab = "cancel"

      this.cancel_btn = false;
      this.cancel_re_btn = false;
      this.recall_btn = false;
      this.print_btn = true;
      this.accept_btn = false;
      this.reject_btn = false;
      this.process_btn = false;
      this.completeBtn = false;
      this.assign_dirver_btn = false;
      let senddata = {
        "page_no": this.p2,
        "order_status": 2,
        "terminal_id": this.terminal_id,
        "clerk_id": this.filterobj.clerk_id,
        "transaction_no": this.filterobj.transaction_no,
        "order_no": this.filterobj.database_id,
        "tender_profile_id": this.filterobj.ord_type_id,
        "filter_tender_profile_id": this.orderTypeData,
        "mobile_no": this.filterobj.mobile_no,
        "name": this.filterobj.name
        // "store_id": this.store_id,
        // "date": (this.datePipe.transform(new Date(), "yyyy-MM-dd")),
        // "terminal_id": this.terminal_id,
        // "clerk_id": this.currentUser.id
      }
      // this.getOrderData(senddata)
      this.getOrdersfromWebSql(senddata)
    }
    if (tabName === 'pending') {
      this.activeTab = "pending"
      this.cancel_btn = false;
      this.cancel_re_btn = false;
      this.recall_btn = false;
      this.print_btn = true;
      this.accept_btn = true;
      this.reject_btn = true;
      this.process_btn = false;
      this.completeBtn = false;
      this.assign_dirver_btn = false;
      let senddata = {
        "page_no": this.p3,
        "order_status": 3,
        "terminal_id": this.terminal_id,
        "clerk_id": this.filterobj.clerk_id,
        "transaction_no": this.filterobj.transaction_no,
        "order_no": this.filterobj.database_id,
        "tender_profile_id": this.filterobj.ord_type_id,
        "filter_tender_profile_id": this.orderTypeData,
        "mobile_no": this.filterobj.mobile_no,
        "name": this.filterobj.name
      }
      this.getOrdersfromWebSql(senddata)

    }
    if (tabName === 'accepted') {
      this.activeTab = "accepted"
      this.cancel_btn = true;
      this.cancel_re_btn = false;
      this.recall_btn = false;
      this.print_btn = true;
      this.accept_btn = false;
      this.reject_btn = false;
      this.process_btn = true;
      this.completeBtn = false;
      this.assign_dirver_btn = false;
      let senddata = {
        "page_no": this.p4,
        "order_status": 4,
        "terminal_id": this.terminal_id,
        "clerk_id": this.filterobj.clerk_id,
        "transaction_no": this.filterobj.transaction_no,
        "order_no": this.filterobj.database_id,
        "tender_profile_id": this.filterobj.ord_type_id,
        "filter_tender_profile_id": this.orderTypeData,
        "mobile_no": this.filterobj.mobile_no,
        "name": this.filterobj.name
      }
      this.getOrdersfromWebSql(senddata)
    }

    if (tabName === 'inprocess') {
      this.activeTab = "inprocess"
      this.cancel_btn = true;
      this.cancel_re_btn = false;
      this.recall_btn = false;
      this.print_btn = true;
      this.accept_btn = false;
      this.reject_btn = false;
      this.process_btn = false;
      this.completeBtn = true;
      this.assign_dirver_btn = false;
      let senddata = {
        "page_no": this.p4,
        "order_status": 6,
        "terminal_id": this.terminal_id,
        "clerk_id": this.filterobj.clerk_id,
        "transaction_no": this.filterobj.transaction_no,
        "order_no": this.filterobj.database_id,
        "tender_profile_id": this.filterobj.ord_type_id,
        "filter_tender_profile_id": this.orderTypeData,
        "mobile_no": this.filterobj.mobile_no,
        "name": this.filterobj.name
      }
      this.getOrdersfromWebSql(senddata)
    }
    if (tabName === 'indelivery') {
      this.activeTab = "indelivery"
      this.cancel_btn = true;
      this.cancel_re_btn = false;
      this.recall_btn = false;
      this.print_btn = true;
      this.accept_btn = false;
      this.reject_btn = false;
      this.process_btn = false;
      this.completeBtn = true;
      this.assign_dirver_btn = false;
      let senddata = {
        "page_no": this.p4,
        "order_status": 5,
        "terminal_id": this.terminal_id,
        "clerk_id": this.filterobj.clerk_id,
        "transaction_no": this.filterobj.transaction_no,
        "order_no": this.filterobj.database_id,
        "tender_profile_id": this.filterobj.ord_type_id,
        "filter_tender_profile_id": this.orderTypeData,
        "mobile_no": this.filterobj.mobile_no,
        "name": this.filterobj.name
      }
      this.getOrdersfromWebSql(senddata)
    }
  }

  async acceptOrder() {
    this.modalRef.hide();
    if (this.internetStatus == "Online") {
      this.spinner.show();
      let senddata = {
        "order_id": this.cartData.database_id,
        "order_status": 4,
        "terminal_id": this.terminal_id,
        "cancel_reason": ""
      }
      let orderStatus = await this._posService.orderUpdate(senddata)
      if (orderStatus["status"] == 1) {
        await this.sqlService.dbInstance.executeSql(`UPDATE orders SET order_status = 4 where id = ` + this.cartData.id, []).then(async (data) => {

          let obj = {
            "page_no": this.p3,
            "order_status": 3,
            "terminal_id": this.terminal_id,
            "clerk_id": this.filterobj.clerk_id,
            "transaction_no": this.filterobj.transaction_no,
            "order_no": this.filterobj.database_id,
            "tender_profile_id": this.filterobj.ord_type_id
          }
          this.getOrdersfromWebSql(obj)

          // if (this.internetStatus != "Offline") {
          // await this._posService.placeOrder(this.cartData);
          // let status = await this._posService.placeOrder(this.cartData);

          // if (status['status'] != 0) {
          //   let serverOrder = status['data'];
          //   let update = await this.sqlService.updateOrderSyncInWbSql(this.cartData, serverOrder);
          // }
          // }

        })
        this.clearData()

      } else {
        this.toastr.warning(orderStatus['message'])

      }
      this.spinner.hide()
    } else {
      this.toastr.warning(this.translate.instant("System is Offline"))
    }
  }

  // async updateOrderSyncInWbSql(order, serverOrder) {
  //   await this.sqlService.dbInstance.executeSql(`UPDATE orders SET isSync = 1, database_id = ` + serverOrder.id + `  where id = ` + order.id, [])
  // }


  async processOrder() {
    this.modalRef.hide();
    if (this.internetStatus == "Online") {
      this.spinner.show()
      let senddata = {
        "order_id": this.cartData.database_id,
        "order_status": 6,
        "terminal_id": this.terminal_id,
        "cancel_reason": ""
      }
      let orderStatus = await this._posService.orderUpdate(senddata)
      if (orderStatus["status"] == 1) {
        await this.sqlService.dbInstance.executeSql(`UPDATE orders SET  order_status = 6 where id = ` + this.cartData.id, []).then(async (data) => {
          let obj = {
            "page_no": this.p3,
            "order_status": 4,
            "terminal_id": this.terminal_id,
            "clerk_id": this.filterobj.clerk_id,
            "transaction_no": this.filterobj.transaction_no,
            "order_no": this.filterobj.database_id,
            "tender_profile_id": this.filterobj.ord_type_id
          }
          this.getOrdersfromWebSql(obj)
          this.clearData()
        })
      } else {
        this.toastr.warning(orderStatus["message"])
      }
      this.spinner.hide()
    } else {
      this.toastr.warning(this.translate.instant("System is Offline"))
    }

  }

  async completeOrder() {
    this.modalRef.hide();
    if (this.internetStatus == "Online") {
      this.spinner.show()
      let senddata = {
        "order_id": this.cartData.database_id,
        "order_status": 1,
        "terminal_id": this.terminal_id,
        "cancel_reason": ""
      }
      let orderStatus = await this._posService.orderUpdate(senddata)
      if (orderStatus["status"] == 1) {
        let or_status
        if (this.activeTab == "inprocess") {
          or_status = 6
        }
        if (this.activeTab == "indelivery") {
          or_status = 5
        }
        await this.sqlService.dbInstance.executeSql(`UPDATE orders SET order_status = 1 where id = ` + this.cartData.id, []).then(async (data) => {
          let obj = {
            "page_no": this.p3,
            "order_status": or_status,
            "terminal_id": this.terminal_id,
            "clerk_id": this.filterobj.clerk_id,
            "transaction_no": this.filterobj.transaction_no,
            "order_no": this.filterobj.database_id,
            "tender_profile_id": this.filterobj.ord_type_id
          }
          this.getOrdersfromWebSql(obj)
        })
        this.clearData()
      } else {
        this.toastr.warning(orderStatus["message"])
      }
      this.spinner.hide()
      this.modalRef.hide()
    } else {
      this.toastr.warning(this.translate.instant("System is Offline"))
    }
  }

  // async processOrder() {
  //   await this.sqlService.dbInstance.executeSql(`UPDATE orders SET isSync = 0, order_status = 6 where id = ` + this.cartData.id, []).then(async (data) => {
  //     let senddata = {
  //       "page_no": this.p4,
  //       "order_status": 6,
  //       "terminal_id": this.terminal_id
  //     }
  //     this.getOrdersfromWebSql(senddata)
  //   })
  // }

  clearData() {
    this.cartData = {};
    this.order_items = [];
    this.grandTotal = {
      sub_total: 0,
      tax_amt: 0,
      grand_total: 0,
      total_qty: 0
    };
    this.config = {
      tax: 5,
      delivery_charge: 0,
      disValue: 0
    }

  }

  // getCancelOrderReasonsByIdIndexedDb() {
  //   this.indexdbservice.getAllByIndex('reasons', 'type', IDBKeyRange.only('4')).subscribe((reason) => {
  //     console.log(reason);
  //     let reasons = reason
  //     var ins = [];
  //     for (var i = 0; i < reasons.length; i++) {
  //       var str = { name: reasons[i].title, id: reasons[i] };
  //       ins.push(str);
  //     }
  //     this.reasonList = ins;
  //     console.log(this.reasonList)

  //   });
  // }

  // getAdjustmentOrderReasonsByIdIndexedDb() {
  //   this.indexdbservice.getAllByIndex('reasons', 'type', IDBKeyRange.only('7')).subscribe((reason) => {
  //     console.log(reason);
  //     let reasons = reason
  //     var ins = [];
  //     for (var i = 0; i < reasons.length; i++) {
  //       var str = { name: reasons[i].title, id: reasons[i] };
  //       ins.push(str);
  //     }
  //     this.reasonList = ins;
  //     console.log(this.reasonList)

  //   });
  // }

  getReasonsFromWebSql(reasonType) {
    this.sqlService.dbInstance.executeSql(`SELECT * FROM reasons  where type = "` + reasonType + `"`, []).then(async (data) => {

      let list = []
      for (let i = 0; i < data.rows.length; i++) {
        list.push(data.rows.item(i));
      }
      var ins = [];
      for (var i = 0; i < list.length; i++) {
        var str = { name: list[i].title, id: list[i] };
        ins.push(str);
      }
      this.reasonList = ins;

    });
  }

  getReasons(reasonType) {
    this.spinner.show();
    let obj = {
      "terminal_id": 1,
      "type": reasonType
    }
    this.cmsService.getReasons(obj)
      .subscribe(
        res => {
          if (res.status == 1) {
            let reasons = res.data
            var ins = [];
            for (var i = 0; i < reasons.length; i++) {
              var str = { name: reasons[i].title, id: reasons[i] };
              ins.push(str);
            }
            this.reasonList = ins;
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
        },
        error => {
          console.log(error);
          this.spinner.hide();
        });
  }

  getTenderList() {
    this._posService.getTenderList()
      .subscribe(
        res => {
          // console.log(res)
          this.orderTypes = res;
          localStorage.setItem('order_type', JSON.stringify(res));
        },
        err => {
          console.log(err)
        }
      )
  }

  ordType(template: TemplateRef<any>) {
    // if(this.storePosBehavior.is_new_order_authorization_required == 1) {
      localStorage.removeItem("selectedTable");
      localStorage.removeItem("fromTable");
      localStorage.removeItem('rawCartItems');
      localStorage.removeItem('rawcartDiscount');
      this.modalRef = this.modalService.show(template);
      this.modalRef.setClass('modal-lg modal-dialog-centered');
    // } else {
    //   this.toastr.warning(this.translate.instant("Not Authorization"))
    // }
  }

  goToPos(type_id) {
    this.tenderTypeId = type_id;
    this.passData.recallCartData({})
    this.passData.tableNewOrder({});
    localStorage.removeItem("selectedTable");
    localStorage.removeItem("fromTable");
    this._router.navigate(['/pos'], { queryParams: { order_type: type_id } })
  }

  // goToPos(orType) {
  //   console.log(orType)
  //   localStorage.setItem('selectedOrderType', JSON.stringify(orType))
  //   this.tenderTypeId = orType.database_id;
  //   console.log(this.tenderTypeId)
  //   this.passData.recallCartData({})
  //   // // this.modalService.hide()
  //   this._router.navigate(['/pos'], { queryParams: { order_type: this.tenderTypeId } })
  // }

  remove() {
    if (this.textboxname == "username") {
      this.currentNumber = this.user.username.toString().slice(0, -1)
      this.user.username = this.currentNumber
    }
    if (this.textboxname == "password") {
      this.currentNumber = this.user.password.toString().slice(0, -1)
      this.user.password = this.currentNumber
    }

    if (this.textboxname == "phone") {
      this.currentNumber = this.filterobj.phone.toString().slice(0, -1)
      this.filterobj.phone = this.currentNumber
    }
    if (this.textboxname == "clerk_id") {
      this.currentNumber = this.filterobj.clerk_id.toString().slice(0, -1)
      this.filterobj.clerk_id = this.currentNumber
    }
    if (this.textboxname == "transaction_no") {
      this.currentNumber = this.filterobj.transaction_no.toString().slice(0, -1)
      this.filterobj.transaction_no = this.currentNumber
    }
    if (this.textboxname == "database_id") {
      this.currentNumber = this.filterobj.database_id.toString().slice(0, -1)
      this.filterobj.database_id = this.currentNumber
    }
    if (this.textboxname == "mobile_no") {
      this.currentNumber = this.filterobj.mobile_no.toString().slice(0, -1)
      this.filterobj.mobile_no = this.currentNumber
    }

  }

  public getNumber(v: string) {

    if (this.textboxname == "username") {
      if (this.waitForSecondNumber) {
        this.currentNumber = v;
        this.waitForSecondNumber = false;
      } else {
        this.currentNumber === '0' ? this.currentNumber = v : this.currentNumber += v;
        this.user.username = this.currentNumber
      }
    }
    if (this.textboxname == "password") {
      if (this.waitForSecondNumber) {
        this.currentNumber = v;
        this.waitForSecondNumber = false;
      } else {
        this.currentNumber === '0' ? this.currentNumber = v : this.currentNumber += v;
        this.user.password = this.currentNumber
      }
    }

    if (this.textboxname == "phone") {
      if (this.waitForSecondNumber) {
        this.currentNumber = v;
        this.waitForSecondNumber = false;
      } else {
        this.currentNumber === '0' ? this.currentNumber = v : this.currentNumber += v;
        this.filterobj.phone = this.currentNumber
      }
    }

    if (this.textboxname == "clerk_id") {
      if (this.waitForSecondNumber) {
        this.currentNumber = v;
        this.waitForSecondNumber = false;
      } else {
        this.currentNumber === '0' ? this.currentNumber = v : this.currentNumber += v;
        this.filterobj.clerk_id = this.currentNumber
      }
    }

    if (this.textboxname == "transaction_no") {
      if (this.waitForSecondNumber) {
        this.currentNumber = v;
        this.waitForSecondNumber = false;
      } else {
        this.currentNumber === '0' ? this.currentNumber = v : this.currentNumber += v;
        this.filterobj.transaction_no = this.currentNumber
      }
    }

    if (this.textboxname == "database_id") {
      if (this.waitForSecondNumber) {
        this.currentNumber = v;
        this.waitForSecondNumber = false;
      } else {
        this.currentNumber === '0' ? this.currentNumber = v : this.currentNumber += v;
        this.filterobj.database_id = this.currentNumber
      }
    }

    if (this.textboxname == "mobile_no") {
      if (this.waitForSecondNumber) {
        this.currentNumber = v;
        this.waitForSecondNumber = false;
      } else {
        this.currentNumber === '0' ? this.currentNumber = v : this.currentNumber += v;
        this.filterobj.mobile_no = this.currentNumber
      }
    }

    if (this.textboxname == "name") {
      if (this.waitForSecondNumber) {
        this.currentNumber = v;
        this.waitForSecondNumber = false;
      } else {
        this.currentNumber === '0' ? this.currentNumber = v : this.currentNumber += v;
        this.filterobj.name = this.currentNumber
      }
    }
  }

  onfocus(name) {
    var tempData = localStorage.getItem('platform')
    if (tempData == 'web') {
      this.isPlatform = false;
    } else {
      this.isPlatform = true;
    }
    this.currentNumber = "0"
    this.textboxname = name;
  }

  checkInModalFianance(template: TemplateRef<any>, btnName, type_id) {
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-dialog-centered modal-lg');
    this.tenderTypeId = type_id;
    this.clickedBtn = btnName;
    this.onfocus("username")
  }

  ClickToCheckInFianance() {

    this.user.store_id = this.store_id;
    let checkinUser = JSON.parse(localStorage.getItem('checkinUserList'));
    var checkLogin = 0
    var localuser
    if (checkinUser) {
      if (this.is_login_with_pin == 0) {
         localuser = checkinUser.find(x => Number(x.userDetail.username) === Number(this.user.username));
        if ((localuser) && Number(localuser.userDetail.username) === Number(this.user.username) && localuser.userDetail.password === this.user.password
          && localuser.userDetail.store_id === this.user.store_id) {
          checkLogin = 1
        } 
        // else {
        //   this.toastr.error(this.translate.instant("Username or Password not match"))
        // }
      } else {
         localuser = checkinUser.find(x => Number(x.userDetail.username) === Number(this.user.username));
        checkLogin = 1
      }
      if (localuser) {
        if (checkLogin == 1) {
          localStorage.setItem('currentUser', JSON.stringify(localuser))
          localStorage.setItem('token', JSON.stringify(localuser.user_token));
          var role_obj = localuser.roles[0];
          this.permissionList = []
          role_obj.permissions.forEach(element => {
            this.permissionList.push(element.name)
          });
          localStorage.setItem('permissions', JSON.stringify(this.permissionList))

          var role_obj = localuser.roles[0];
          this.permissionList = []
          role_obj.permissions.forEach(element => {
            this.permissionList.push(element.name)
          });
          localStorage.setItem('permissions', JSON.stringify(this.permissionList))

          if (this.clickedBtn == 'tenderbtn') {
            this._router.navigate(['/pos'], { queryParams: { order_type: this.tenderTypeId } })
          }

          if (this.clickedBtn == 'recall') {
            // this.cartData.order_id = this.cartData.database_id;
            localStorage.removeItem('rawCartItems');
            localStorage.removeItem('rawAdjustedData');
            localStorage.removeItem('rawcartCustomer');
            localStorage.removeItem('rawcartDiscount');
            localStorage.removeItem('mycart');
            this.cust = {
              id: this.cartData.customer_id,
              name: this.cartData.customer_name.split(' ')[0],
              lname: this.cartData.customer_name.split(' ')[1],
              phone: this.cartData.customer_phone
            }
            localStorage.setItem("rawcartCustomer", JSON.stringify(this.cust));

            let discount = {
              discount_id: this.cartData.discount_id,
              discount_rate: this.cartData.discount_rate,
              discount_type: this.cartData.discount_type,
            }
            localStorage.setItem("rawcartDiscount", JSON.stringify(discount));

            let raw_order_details = this.cartData.order_details;
            if (raw_order_details?.length > 0) {
              raw_order_details.forEach((item, index) => {
                item.database_id = item.pricelookup_id;
                item.index = index;
              });
            }
            localStorage.setItem("rawCartItems", JSON.stringify(raw_order_details));

            this.cartData.order_id = this.cartData.local_transaction_no;
            // console.log(this.cartData);
            localStorage.setItem("rawCartItems", JSON.stringify(this.order_items))
            if (this.clickedBtn == 'recall') {
              this._router.navigate(['/pos'], { queryParams: { order_type: this.tenderTypeId, buttonType: 'RC' } })
            }
            if (this.clickedBtn == 'adjust_reenter') {
              this._router.navigate(['/pos'], { queryParams: { order_type: this.tenderTypeId, buttonType: 'CR' } })
            }
            // this.createMycartIndexdb();
            // this.passData.recallCartData(this.cartData)
            // this._router.navigate(['/pos'], { queryParams: { order_type: this.tenderTypeId, buttonType: 'RC' } })
          }
        } else {
          this.toastr.error(this.translate.instant("Username or Password not match"))
        }
      } else {
        this.toastr.error(this.translate.instant("You haven't clock in. Please clock in first"))
      }
    }
  }

  // createMycartIndexdb() {
  //   this.indexdbservice.clear('mycart');
  //   this.indexdbservice.add('mycart', this.cartData).toPromise()
  //     .then(
  //       res => {
  //         // console.log(res)
  //         // this._router.navigate(['/pos'], { queryParams: { order_type: this.tenderTypeId, buttonType: 'RC' } })
  //         if (this.clickedBtn == 'recall') {
  //           this._router.navigate(['/pos'], { queryParams: { order_type: this.tenderTypeId, buttonType: 'RC' } })
  //         }
  //         if (this.clickedBtn == 'adjust_reenter') {
  //           this._router.navigate(['/pos'], { queryParams: { order_type: this.tenderTypeId, buttonType: 'CR' } })

  //         }
  //       },
  //       error => {
  //         console.log(error);
  //       });
  // }

  ClickToCheckLogin() {
    // this.spinner.show()
    this.user.store_id = this.store_id;
    let checkinUser = JSON.parse(localStorage.getItem('checkinUserList'));
    var checkLogin = 0
    var localuser
    if (checkinUser) {
      if (this.is_login_with_pin == 0) {
         localuser = checkinUser.find(x => Number(x.userDetail.username) === Number(this.user.username))
        if ((localuser) && Number(localuser.userDetail.username) === Number(this.user.username) && localuser.userDetail.password === this.user.password
          && localuser.userDetail.store_id === this.user.store_id) {
          checkLogin = 1
        }
        //  else {
        //   this.toastr.error(this.translate.instant("Username or Password not match"))
        // }
      } else {
         localuser = checkinUser.find(x => Number(x.pin) === Number(this.user.username))
        checkLogin = 1
      }
      if (localuser) {
        if (checkLogin == 1) {
          localStorage.setItem('currentUser', JSON.stringify(localuser))
          localStorage.setItem('token', JSON.stringify(localuser.user_token));
          var role_obj = localuser.roles[0];
          this.permissionList = []
          role_obj.permissions.forEach(element => {
            this.permissionList.push(element.name)
          });
          localStorage.setItem('permissions', JSON.stringify(this.permissionList))

          var role_obj = localuser.roles[0];
          this.permissionList = []
          role_obj.permissions.forEach(element => {
            this.permissionList.push(element.name)
          });
          localStorage.setItem('permissions', JSON.stringify(this.permissionList))

          if (this.clickedBtn == 'cancel') {
            setTimeout(() => {
              this.openReasonModel(this.canOrder, 4)
            }, 1000);
          }
          if (this.clickedBtn == 'adjust_reenter') {
            setTimeout(() => {
              this.openReasonModel(this.canOrder, 7)
            }, 1000);
          }
        } else {
          this.toastr.error(this.translate.instant("Username or Password not match"))
        }
      } else {
        this.toastr.error(this.translate.instant("You haven't clock in. Please clock in first"))
      }
    }
  }

  openReasonModelReject(template: TemplateRef<any>, suretemplate: TemplateRef<any>, reasonType) {
    this.clickedBtn = 'cancel';
    this.sureTemp = suretemplate;
    this.reasonModel = template;
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-dialog-centered');
    this.getReasonsFromWebSql(reasonType)


  }

  openReasonModel(template: TemplateRef<any>, reasonType) {
    this.reasonModel = template;
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-dialog-centered');
    this.getReasonsFromWebSql(reasonType)
  }

  declineCancel() {
    this.modalService.hide()
  }

  confirmCancel() {
    this.modalService.hide();
    this.cancelOrder()
  }

  openConfirmationModel() {
    this.modalService.hide(this.reasonModel)

    setTimeout(() => {
      this.modalRef = this.modalService.show(this.sureTemp);
      this.modalRef.setClass('modal-dialog-centered');
    }, 1000);
  }

  checkingCancel() {
    if (this.cnlOrder.comment) {
      if (this.clickedBtn == 'cancel') {
        // this.cancelOrder()
        if (this.sureTemp) {
          this.openConfirmationModel()
        } else {
          this.cancelOrder()
        }
      }
      if (this.clickedBtn == 'adjust_reenter') {
        localStorage.removeItem('rawCartItems');
        localStorage.removeItem('rawcartCustomer');
        localStorage.removeItem('rawcartDiscount');

        this.cust = {
          id: this.cartData.customer_id,
          name: this.cartData.customer_name.split(' ')[0],
          lname: this.cartData.customer_name.split(' ')[1],
          phone: this.cartData.customer_phone
        }
        localStorage.setItem("rawcartCustomer", JSON.stringify(this.cust));

        let discount = {
          discount_id: this.cartData.discount_id,
          discount_rate: this.cartData.discount_rate,
          discount_type: this.cartData.discount_type,
        }
        localStorage.setItem("rawcartDiscount", JSON.stringify(discount));

        let raw_order_details = this.cartData.order_details;
        if (raw_order_details?.length > 0) {
          raw_order_details.forEach((item, index) => {
            item.database_id = item.pricelookup_id;
            item.index = index;
          });
        }
        console.log("this.cartData", this.cartData)
        localStorage.setItem("rawCartItems", JSON.stringify(raw_order_details));

        let adjustedData = {
          adjustReason: this.cnlOrder.comment,
          // this.cartData.adujusted_order_id = this.cartData.database_id;
          adujusted_order_id: this.cartData.store_order_sequence_id

        }
        // console.log('adjustedData',adjustedData)
        localStorage.setItem("rawAdjustedData", JSON.stringify(adjustedData));

        if (this.clickedBtn == 'recall') {
          this._router.navigate(['/pos'], { queryParams: { order_type: this.tenderTypeId, buttonType: 'RC' } })
        }
        if (this.clickedBtn == 'adjust_reenter') {
          this._router.navigate(['/pos'], { queryParams: { order_type: this.tenderTypeId, buttonType: 'CR' } })
        }
        // this.createMycartIndexdb();
        // this.passData.recallCartData(this.cartData);
        // this._router.navigate(['/pos'], { queryParams: { order_type: this.tenderTypeId, buttonType: 'CR' } })
      }
      this.modalRef.hide()
    } else {
      this.toastr.warning(this.translate.instant("Please select cancel reason"))
    }
  }

  async cancelOrder() {

    let isSync = 0;
    //if (this.activeTab == "completed") {
    if (this.internetStatus != "Offline") {
      isSync = 1
    } else {
      isSync = 0
    }
    //}
    this.spinner.show()
    var obj = {
      terminal_id: this.terminal_id,
      store_id: this.store_id,
      clerk_id: this.currentUser.id,
      order_status: 2,
      order_id: this.cartData.database_id,
      cancel_reason: this.cnlOrder.comment ? this.cnlOrder.comment.title : ''
    }
    //}
    // alert(this.cartData.database_id)
    // alert(isNaN(this.cartData.database_id))
    // return

    if (this.internetStatus == "Offline" || isNaN(this.cartData.database_id)) {
      // only entry into web sql, when it comes to online sync if isSync = 0
      this.sqlService.dbInstance.executeSql(`UPDATE orders SET isSync = ` + isSync + ` , order_status = 2  where id = ` + this.cartData.id, []).then(async (data) => {

        let order_status = 0;
        if (this.activeTab == "completed") {
          order_status = 1;
        }
        if (this.activeTab == "pending") {
          order_status = 3;
        }

        let table_id = 0;


        await this.sqlService.dbInstance.executeSql(`SELECT id,table_id,order_status FROM orders WHERE id = ` + this.cartData.id, [])
          .then(async (orderRaw) => {
            table_id = orderRaw.rows.item(0).table_id;
          }).catch((err) => {
          })

        if (table_id != 0) {
          let sql = `UPDATE store_tables SET booking_status = 0,isSync = 0,order_id = 0,table_occupied_time = 0 ,numberofguest = 0,merge_tables = '0',merge_tables = '0',isMerge ='0' where database_id = ` + table_id;
          let sql_merge = `UPDATE store_tables SET isMerge = '0',isSync = 0 where isMerge = ` + table_id;
          this.sqlService.dbInstance.executeSql(sql_merge, []).then((data) => {
          }).catch((err) => {
            this.toastr.error(this.translate.instant("oops something went wrong"), '');
            this.spinner.hide();
            console.log(err);
          });
          this.sqlService.dbInstance.executeSql(sql, []).then((data) => {
            if (this.internetStatus != "Offline") {
              this.sqlService.syncStoreTable();
            }
          }).catch((err) => {
            this.toastr.error(this.translate.instant("oops something went wrong"), '');
            console.log(err);
          });
        }
        let senddata = {
          "page_no": this.p3,
          "order_status": order_status,
          "terminal_id": this.terminal_id
        }
        this.getOrdersfromWebSql(senddata);
      }).catch((err) => {
        this.toastr.error(this.translate.instant("oops something went wrong"), 'warning');
        this.spinner.hide();
        console.log(err);
      });
      this.spinner.hide()
      this.cnlOrder.comment = null

    } else {
      let cancelStatus = await this._posService.ordercancel(obj);
      if (cancelStatus['status'] == 1) {
        this.sqlService.dbInstance.executeSql(`UPDATE orders SET isSync = ` + isSync + ` , order_status = 2  where id = ` + this.cartData.id, []).then(async (data) => {

          let order_status = 0;
          if (this.activeTab == "completed") {
            order_status = 1;
          }
          if (this.activeTab == "pending") {
            order_status = 3;
          }
          // if (this.internetStatus != "Offline") {
          // online
          //if (this.activeTab == 'completed') {

          // }
          let table_id = 0;
          await this.sqlService.dbInstance.executeSql(`SELECT id,table_id,order_status FROM orders WHERE id = ` + this.cartData.id, [])
            .then(async (orderRaw) => {
              table_id = orderRaw.rows.item(0).table_id;
            }).catch((err) => {
            })

          if (table_id != 0) {
            let sql = `UPDATE store_tables SET booking_status = 0,isSync = 0,order_id = 0,table_occupied_time = 0 ,numberofguest = 0,merge_tables = '0',merge_tables = '0',isMerge ='0' where database_id = ` + table_id;
            let sql_merge = `UPDATE store_tables SET isMerge = '0',isSync = 0 where isMerge = ` + table_id;
            this.sqlService.dbInstance.executeSql(sql_merge, []).then((data) => {
            }).catch((err) => {
              this.toastr.error(this.translate.instant("oops something went wrong"), '');
              this.spinner.hide();
              console.log(err);
            });
            await this.sqlService.dbInstance.executeSql(sql, []).then((data) => {
              if (this.internetStatus != "Offline") {
                this.sqlService.syncStoreTable();
              }
            }).catch((err) => {
              this.toastr.error(this.translate.instant("oops something went wrong"), '');
              console.log(err);
            });
          }

          let senddata = {
            "page_no": this.p3,
            "order_status": order_status,
            "terminal_id": this.terminal_id
          }
          this.getOrdersfromWebSql(senddata);
        }).catch((err) => {
          this.toastr.error(this.translate.instant("oops something went wrong"), 'warning');
          this.spinner.hide();
          console.log(err);
        });
        this.spinner.hide()
        this.cnlOrder.comment = null
      } else {
        this.toastr.warning(cancelStatus['message'])
      }
    }


  }

  // cancelOrder() {
  //   this.spinner.show();
  //   var obj = {
  //     terminal_id: this.terminal_id,
  //     store_id: this.store_id,
  //     clerk_id: this.currentUser.id,
  //     order_status: 2,
  //     order_id: this.cartData.id,
  //     cancel_reason: this.cnlOrder.comment ? this.cnlOrder.comment.title : ''
  //   }
  //   // this.cnlOrder.terminal_id = this.terminal_id
  //   // this.cnlOrder.clerk_id = this.currentUser.id
  //   // this.cnlOrder.order_status = 2
  //   // this.cnlOrder.order = this.cartData.id
  //   // this.cnlOrder.reason = this.cnlOrder.comment.title

  //   this._posService.ordercancel(obj)
  //     .subscribe(
  //       res => {
  //         if (res.status == 1) {
  //           // this.cartData.adujusted_order_id = this.cartData.id
  //           // if (this.clickedBtn == 'adjust_reenter') {
  //           //   this.passData.recallCartData(this.cartData);
  //           //   this._router.navigate(['/pos'], { queryParams: { order_type: this.tenderTypeId, buttonType: 'CR' } })
  //           // }
  //           let senddata = {
  //             "page_no": this.p,
  //             "order_status": 0,
  //             "terminal_id": this.terminal_id,
  //             // "date": (this.datePipe.transform(new Date(), "yyyy-MM-dd")),

  //             // "store_id": this.store_id,
  //             // "clerk_id": this.currentUser.id
  //           }
  //           this.getOrdersfromWebSql(senddata);
  //           this.toastr.success('Success', res.message)
  //           this.spinner.hide();
  //         } else {
  //           this.toastr.error('warning', res.message)
  //           this.spinner.hide();
  //         }
  //       },
  //       error => {
  //         console.log(error);
  //         this.spinner.hide();
  //       });
  // }

  loginModel(template: TemplateRef<any>, btnName) {
    this.modalRef.hide()
    this.tenderTypeId = this.cartData.tender_profile_id;
    this.clickedBtn = btnName
    if (this.storePosBehavior.is_recall_order_authorization_required == 1) {
      this.modalRef = this.modalService.show(template);
      this.modalRef.setClass('modal-dialog-centered modal-lg');
      this.onfocus("username")
    } else {
      let checkinUser = JSON.parse(localStorage.getItem('checkinUserList'));
      var localuser = checkinUser.find(x => Number(x.username) === Number(this.defaultUser))
      if (this.is_login_with_pin == 0) {
        this.user.username = localuser.username
        this.user.password = localuser.userDetail.password
        this.ClickToCheckInFianance();
      } else {
        this.user.username = localuser.pin
        this.ClickToCheckInFianance();
      }
    }
    // var type_id;
    // type_id = this.cartData.tender_profile_id;
    // this.passData.recallCartData(this.cartData);
    // this._router.navigate(['/pos'], { queryParams: { order_type: type_id, buttonType: 'CR' } })
  }

  cancelModel(template: TemplateRef<any>, btnName, reasontemp: TemplateRef<any>, sureTemp: TemplateRef<any>) {
    // this.modalRef = this.modalService.show(template);
    // this.modalRef.setClass('modal-md');
    // this.tenderTypeId = this.cartData.tender_profile_id;
    // this.clickedBtn = btnName
    this.modalRef.hide();
    if (this.activeTab == "completed" || this.activeTab == "saved") {
      this.clickedBtn = btnName
      this.canOrder = reasontemp;
      this.sureTemp = sureTemp;
      this.tenderTypeId = this.cartData.tender_profile_id;
      if(this.clickedBtn == 'cancel') {
        console.log(this.clickedBtn)
        if (this.storePosBehavior.is_order_cancellation_authorization_required == 1) {
          this.modalRef = this.modalService.show(template);
          this.modalRef.setClass('modal-dialog-centered modal-lg');
          this.onfocus("username")
        } else {
          let checkinUser = JSON.parse(localStorage.getItem('checkinUserList'));
          var localuser = checkinUser.find(x => Number(x.username) === Number(this.defaultUser))
          if (this.is_login_with_pin == 0) {
            this.user.username = localuser.username
            this.user.password = localuser.userDetail.password
            this.ClickToCheckLogin();
          } else {
            this.user.username = localuser.pin
            this.ClickToCheckLogin();
          }
        }
      } else {
        console.log(this.clickedBtn)
        if (this.storePosBehavior.is_adjust_and_reenter_authorization_required == 1) {
          this.modalRef = this.modalService.show(template);
          this.modalRef.setClass('modal-dialog-centered modal-lg');
          this.onfocus("username")
        } else {
          let checkinUser = JSON.parse(localStorage.getItem('checkinUserList'));
          var localuser = checkinUser.find(x => Number(x.username) === Number(this.defaultUser))
          if (this.is_login_with_pin == 0) {
            this.user.username = localuser.username
            this.user.password = localuser.userDetail.password
            this.ClickToCheckLogin();
          } else {
            this.user.username = localuser.pin
            this.ClickToCheckLogin();
          }
        }
      }
    } else {
      if (this.internetStatus == "Online") {
        this.clickedBtn = btnName
        this.canOrder = reasontemp;
        this.sureTemp = sureTemp;
        this.tenderTypeId = this.cartData.tender_profile_id;
        if (this.storePosBehavior.is_order_cancellation_authorization_required == 1) {
          this.modalRef = this.modalService.show(template);
          this.modalRef.setClass('modal-dialog-centered modal-lg');
          this.onfocus("username")
        } else {
          let checkinUser = JSON.parse(localStorage.getItem('checkinUserList'));
          var localuser = checkinUser.find(x => Number(x.username) === Number(this.defaultUser))
          if (this.is_login_with_pin == 0) {
            this.user.username = localuser.username
            this.user.password = localuser.userDetail.password
            this.ClickToCheckLogin();
          } else {
            this.user.username = localuser.pin
            this.ClickToCheckLogin();
          }
        }
      } else {
        this.toastr.warning(this.translate.instant("System is Offline"))
      }
    }
  }

  paginateSavedOrder(event) {
    this.p = event;
    let senddata = {
      "page_no": this.p,
      "order_status": 0,
      "terminal_id": this.terminal_id,
      "clerk_id": this.filterobj.clerk_id,
      "transaction_no": this.filterobj.transaction_no,
      "order_no": this.filterobj.database_id,
      "tender_profile_id": this.filterobj.ord_type_id,
      "filter_tender_profile_id": this.orderTypeData,
      "mobile_no": this.filterobj.mobile_no,
      "name": this.filterobj.name
      // "date": (this.datePipe.transform(new Date(), "yyyy-MM-dd")),
      // "store_id": this.store_id,
      // "clerk_id": this.currentUser.id
    }
    this.getOrdersfromWebSql(senddata)

  }

  paginateCancelOrder(event) {
    this.p2 = event;
    let senddata = {
      "page_no": this.p2,
      "order_status": 2,
      "terminal_id": this.terminal_id,
      "clerk_id": this.filterobj.clerk_id,
      "transaction_no": this.filterobj.transaction_no,
      "order_no": this.filterobj.database_id,
      "tender_profile_id": this.filterobj.ord_type_id,
      "filter_tender_profile_id": this.orderTypeData,
      "mobile_no": this.filterobj.mobile_no,
      "name": this.filterobj.name
      // "date": (this.datePipe.transform(new Date(), "yyyy-MM-dd")),
      // "store_id": this.store_id,
      // "clerk_id": this.currentUser.id
    }
    this.getOrdersfromWebSql(senddata)
  }

  paginateCompleteOrder(event) {
    this.p1 = event;

    let senddata = {
      "page_no": this.p1,
      "order_status": 1,
      "terminal_id": this.terminal_id,
      "clerk_id": this.filterobj.clerk_id,
      "transaction_no": this.filterobj.transaction_no,
      "order_no": this.filterobj.database_id,
      "tender_profile_id": this.filterobj.ord_type_id,
      "filter_tender_profile_id": this.orderTypeData,
      "mobile_no": this.filterobj.mobile_no,
      "name": this.filterobj.name
    }
    this.getOrdersfromWebSql(senddata);
  }

  paginatePendingOrder(event) {

    this.p3 = event;
    let senddata = {
      "page_no": this.p3,
      "order_status": 3,
      "terminal_id": this.terminal_id,
      "clerk_id": this.filterobj.clerk_id,
      "transaction_no": this.filterobj.transaction_no,
      "order_no": this.filterobj.database_id,
      "tender_profile_id": this.filterobj.ord_type_id,
      "mobile_no": this.filterobj.mobile_no,
      "name": this.filterobj.name
    }
    this.getOrdersfromWebSql(senddata);
  }

  paginateAcceptedOrder(event) {
    this.p4 = event;
    let senddata = {
      "page_no": this.p4,
      "order_status": 4,
      "terminal_id": this.terminal_id,
      "clerk_id": this.filterobj.clerk_id,
      "transaction_no": this.filterobj.transaction_no,
      "order_no": this.filterobj.database_id,
      "tender_profile_id": this.filterobj.ord_type_id,
      "filter_tender_profile_id": this.orderTypeData,
      "mobile_no": this.filterobj.mobile_no,
      "name": this.filterobj.name
    }
    this.getOrdersfromWebSql(senddata);
  }

  paginateProcessingOrder(event) {
    this.p5 = event;
    let senddata = {
      "page_no": this.p5,
      "order_status": 6,
      "terminal_id": this.terminal_id,
      "clerk_id": this.filterobj.clerk_id,
      "transaction_no": this.filterobj.transaction_no,
      "order_no": this.filterobj.database_id,
      "tender_profile_id": this.filterobj.ord_type_id,
      "mobile_no": this.filterobj.mobile_no,
      "name": this.filterobj.name
    }
    this.getOrdersfromWebSql(senddata);
  }

  paginateDeliveryOrder(event) {
    this.p6 = event;
    let senddata = {
      "page_no": this.p6,
      "order_status": 5,
      "terminal_id": this.terminal_id,
      "clerk_id": this.filterobj.clerk_id,
      "transaction_no": this.filterobj.transaction_no,
      "order_no": this.filterobj.database_id,
      "tender_profile_id": this.filterobj.ord_type_id,
      "filter_tender_profile_id": this.orderTypeData,
      "mobile_no": this.filterobj.mobile_no,
      "name": this.filterobj.name
    }
    this.getOrdersfromWebSql(senddata);
  }

  showOrderModal(template: TemplateRef<any>) {
    if (this.internetStatus == "Online") {
      this.modalRef = this.modalService.show(template);
      this.modalRef.setClass('modal-order-detail modal-md');
      // this.getRiderListFromWebsql()
    } else {
      this.modalRef = this.modalService.show(template);
      this.modalRef.setClass('modal-order-detail modal-md');
      // this.toastr.warning(this.translate.instant('System is offline'))
    }
  }


  assignDriverModel(template: TemplateRef<any>) {
    this.modalRef.hide()
    if (this.internetStatus == "Online") {
      this.modalRef = this.modalService.show(template);
      this.modalRef.setClass('modal-dialog-centered modal-md');
      this.getRiderListFromWebsql()
    } else {
      this.toastr.warning(this.translate.instant('System is offline'))
    }
  }

  getRiderListFromWebsql() {
    this.sqlService.dbInstance.executeSql(`SELECT * FROM riders`, []).then(async (data) => {

      let list = []
      for (let i = 0; i < data.rows.length; i++) {
        list.push(data.rows.item(i));
      }
      // var ins = [];
      // for (var i = 0; i < list.length; i++) {
      //   var str = { name: list[i].title, id: list[i] };
      //   ins.push(str);
      // }
      //
      this.riderList = list;

    });
  }

  assignRider(template: TemplateRef<any>, rider) {
    this.rider = rider;
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-dialog-centered modal-md');
  }

  async confirmAssign() {
    if (this.internetStatus == 'Online') {
      this.modalService.hide()
      this.spinner.show()
      let assignData = {
        "order_id": this.cartData.database_id,
        "rider_id": this.rider.database_id
      }
      let assignStatus = await this._posService.assignRider(assignData)
      // console.log("assign Status :", assignStatus)
      if (assignStatus['status']) {
        let senddata = {
          "order_id": this.cartData.database_id,
          "order_status": 5,
          "terminal_id": this.terminal_id,
          "cancel_reason": ""
        }
        let response = await this._posService.orderUpdate(senddata)
        // console.log("status order: ", response)
        if (response['status'] == 1) {
          await this.sqlService.dbInstance.executeSql(`UPDATE orders  SET order_status = 5 where id = ` + this.cartData.id, []).then(async (data) => {

            let obj = {
              "page_no": this.p3,
              "order_status": 6,
              "terminal_id": this.terminal_id,
              "clerk_id": this.filterobj.clerk_id,
              "transaction_no": this.filterobj.transaction_no,
              "order_no": this.filterobj.database_id,
              "tender_profile_id": this.filterobj.ord_type_id
            }
            this.getOrdersfromWebSql(obj)

            // if (this.internetStatus != "Offline") {
            // await this._posService.placeOrder(this.cartData);
            // let status = await this._posService.placeOrder(this.cartData);
            // let senddata = {
            //   "order_id": this.cartData.database_id,
            //   "order_status": 5,
            //   "terminal_id": this.terminal_id,
            //   "cancel_reason": ""
            // }
            // let status = await this._posService.orderUpdate(senddata)
            // let assignData = {
            //   "order_id": this.cartData.database_id,
            //   "rider_id": this.rider.database_id
            // }

            // let assignStatus = await this._posService.assignRider(assignData)
            // //
            // // if (status['status'] != 0 && assignStatus['status'] != 0) {

            // //   let serverOrder = status['data'];
            // //   let update = await this.sqlService.updateOrderSyncInWbSql(this.cartData, serverOrder);
            // // }
            // }

          })
          this.clearData()
        }

      } else {
        this.toastr.warning(assignStatus['message'])
      }
      this.spinner.hide()
    } else {
      this.toastr.warning(this.translate.instant('System is offline'))
    }
  }

  // async confirmAssign() {
  //   this.modalService.hide()
  //   await this.sqlService.dbInstance.executeSql(`UPDATE orders  SET order_status = 5 where id = ` + this.cartData.id, []).then(async (data) => {

  //     let obj = {
  //       "page_no": this.p3,
  //       "order_status": 6,
  //       "terminal_id": this.terminal_id,
  //       "clerk_id": this.filterobj.clerk_id,
  //       "transaction_no": this.filterobj.transaction_no,
  //       "order_no": this.filterobj.database_id,
  //       "tender_profile_id": this.filterobj.ord_type_id
  //     }
  //     this.getOrdersfromWebSql(obj)

  //     if (this.internetStatus != "Offline") {
  //       // await this._posService.placeOrder(this.cartData);
  //       // let status = await this._posService.placeOrder(this.cartData);
  //       let senddata = {
  //         "order_id": this.cartData.database_id,
  //         "order_status": 5,
  //         "terminal_id": this.terminal_id,
  //         "cancel_reason": ""
  //       }
  //       let status = await this._posService.orderUpdate(senddata)
  //       let assignData = {
  //         "order_id": this.cartData.database_id,
  //         "rider_id": this.rider.database_id
  //       }

  //       let assignStatus = await this._posService.assignRider(assignData)
  //       //
  //       // if (status['status'] != 0 && assignStatus['status'] != 0) {

  //       //   let serverOrder = status['data'];
  //       //   let update = await this.sqlService.updateOrderSyncInWbSql(this.cartData, serverOrder);
  //       // }
  //     }

  //   })
  //   this.clearData()
  // }

  declineAssign() {
    this.modalService.hide()
  }

  closeSideModel() {
    this.modalRef.hide()
  }

  calculateDuration(table_occupied_time) {
    this.currentDate = new Date();
    var hours = '';
    var minutes = '';
    var seconds = '';
    var diff = this.currentDate.getTime() - new Date(table_occupied_time).getTime();
    var days = Math.floor(diff / (60 * 60 * 24 * 1000));
    var temphours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
    var tempminutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (temphours * 60));
    var tempseconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (temphours * 60 * 60) + (tempminutes * 60));
    if (temphours == 0) {
      hours = "00"
    } else {
      if (temphours < 10) {
        hours = "0" + temphours.toString()
      } else {
        hours = temphours.toString()
      }
    }
    if (tempminutes == 0) {
      minutes = "00"
    } else {

      if (tempminutes < 10) {
        minutes = "0" + tempminutes.toString()
      } else {
        minutes = tempminutes.toString()
      }
    }
    if (tempseconds == 0) {
      seconds = "00"
    } else {

      if (tempseconds < 10) {
        seconds = "0" + tempseconds.toString()
      } else {
        seconds = tempseconds.toString()
      }
    }
    if (temphours > 0) {
      return tempminutes + (temphours * 60) + ":" + seconds;
    } else {
      return minutes + ":" + seconds;
    }
    //return hours + ":" + minutes + ":" + seconds

  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async showOrder(order) {
    console.log("startorder", order);
    // this.getAllTables()
    // console.log('this.orderListshowOrder',this.orderList)
    debugger
    if (order.table_id != 0) {
      await this.sqlService.dbInstance.executeSql(`SELECT * FROM store_tables where database_id =` + order.table_id, []).then(async (data) => {
        let list = [];
        for (let i = 0; i < data.rows.length; i++) {
          list.push(data.rows.item(i));
        }
        list.forEach(async element => {
          if (element.table_occupied_time != 0) {
            counter.subscribe(() => {
              element.timecounter = this.calculateDuration(element.table_occupied_time);
            });
          } else {
            element.timecounter = "00:00";
          }
          if (element.id != 0) {
            element.order = await this.getOrdersByTableidfromWebSql(element.database_id);
          } else {
            element.order = {};
          }

        });
        this.selected_table = list[0];
      })
    }

    if (order.delivery_type == 2 && this.activeTab == "inprocess") {
      // if((order.delivery_type == 0 || order.delivery_type == 1) && this.activeTab == "inprocess"){
      this.assign_dirver_btn = true
    } else {
      this.assign_dirver_btn = false
    }

    if (this.activeTab == "completed") {
      if (order.device_type == 1 || order.device_type == 2) {
        this.cancel_re_btn = false
      } else {
        this.cancel_re_btn = true
      }
    }
    this.orderList.forEach(element => {
      if (element.id === order.id) {
        element.selectionColor = true
      } else {
        element.selectionColor = false;
      }
    });

    let order_items = order.order_details;
    order_items.forEach(async element => {
      // debugger
      console.log('order_items element', element);



      /* let addAddOnsvalid = await this._dataService.IsJsonString(element['add_addons_detail']);
      if (addAddOnsvalid) {
        let add_addons_detail_array = JSON.parse(element['add_addons_detail']);
        //element['json_add_addons_detail'] = JSON.parse(element['add_addons_detail']);
        element['add_addons_detail'] = add_addons_detail_array;
          if(element.is_substitute == 0) {
            add_addons_detail_array.forEach(addonelement => {
             element.cal_price = (Number(element.cal_price)) + (Number(addonelement.price)) * (Number(element.pricelookup_qty))
           });
         }
      }else{
        let add_addons_detail_array = element['add_addons_detail'];
        //element['json_add_addons_detail'] = element['add_addons_detail'];
          if(element.is_substitute == 0) {
            add_addons_detail_array.forEach(addonelement => {
             element.cal_price = (Number(element.cal_price)) + (Number(addonelement.price)) * (Number(element.pricelookup_qty))
           });
         }
      } */
      let addAddOnsvalid = this._dataService.IsJsonString(element['add_addons_detail']);
      if (addAddOnsvalid) {
        element['add_addons_detail'] = JSON.parse(element['add_addons_detail']);
      }

      //END Add AddsOn

      //Remove AddsOn
      let removeAddonsValid = this._dataService.IsJsonString(element['remove_addons_detail']);
      if (removeAddonsValid) {
        element['remove_addons_detail'] = JSON.parse(element['remove_addons_detail']);
      }
    });

    /* this.order_items = this._dataService.getCartData(order_items);
 
     order.order_details = this.order_items;
     console.log("order",order)
     this.cartData = order;
 
     this.cartData.order_details = this.order_items;
 
     console.log('this.cartData',this.cartData) */


    this.order_items = await this._dataService.getCartData(order_items);
    await this.delay(100);
    order.order_details = this.order_items;
    this.cartData = order;
    //this.cartData.order_details = this.order_items;
    console.log("this.cartData", this.cartData)


    let temp = []
    this.cartData.order_type.forEach(element => {
      if (element.order_price > 0) {
        temp.push(element.order_type_name)
      }
    });

    this.cartData.tender_type_name = temp.toString();
    this.order_store_detail = await this.sqlService.dbInstance.executeSql(`SELECT taxes FROM order_store_detail WHERE order_id = ` + order.id, [])
    let list = [];
    this.taxList = [];

    if (this.order_store_detail.rows?.length > 0) {
      //this.order_store_detail.rows.forEach((value, key) =>{

      for (let i = 0; i < this.order_store_detail.rows.length; i++) {
        let taxes = JSON.parse(this.order_store_detail.rows.item(i).taxes);
        //console.log(taxes);
        if (taxes) {
          taxes.forEach((value, key) => {
            if (value?.id) {
              this.taxList.push(value);
            }
          });
        }

      }
      //});
      //this.taxList = JSON.parse(this.order_store_detail.rows[key].taxes);
    }

    if (this.taxList?.length > 0) {
      const grouped = this.groupBy(this.taxList, tax => tax.id);
      grouped.forEach((value, key) => {
        let tax_list = value;
        let cal = 0;
        let name;
        let tax_value;
        tax_list.forEach(tax => {
          cal += tax.tax_cal;
          name = tax.display_name;
          if (tax.tax_type == "1") {
            tax_value = tax.tax_value + " %"
          } else {
            tax_value = tax.tax_value + " ₹"
          }

        });
        let mycal = {
          name: name,
          amt: cal.toFixed(2),
          tax_value: tax_value
        }
        list.push(mycal)
      });
    }




    this.taxList = list;

    this.order_store_detail_data = await this.sqlService.dbInstance.executeSql(`SELECT customer_address FROM order_store_detail WHERE order_id = ` + order.id, [])
    // console.log('this.order_store_detail_data', this.order_store_detail_data)
    let addList = [];
    this.addressList = [];
    if (this.order_store_detail_data.rows?.length > 0) {
      for (let i = 0; i < this.order_store_detail_data.rows.length; i++) {
        let custAdd = JSON.parse(this.order_store_detail_data.rows.item(i).customer_address);
        addList = custAdd
        // console.log(this.tempAdd);
      }
      this.tempAdd = addList
    }


    this.cartData.order_details.forEach((item) => {
      item.add_addons_detail.forEach((add_addons_detail) => {
        console.log("sdf", add_addons_detail.name)
      })

    })
    console.log("order_details", this.cartData.order_details)
    // console.log('this.order_items')
    // console.log(this.order_items)
  }

  groupBy(taxList, keyGetter) {
    const map = new Map();
    taxList.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  async syncfromMobileOrders() {
    this.sync_img = "assets/images/sync.gif";
    this.syncDisabled = true;
    await this.sqlService.syncfromMobileOrders()
    this.sync_img = "assets/images/sync_white.png";
    this.syncDisabled = false;
  }

  // syncfromMobileOrders() {
  //   if (this.internetStatus == "Online") {

  //     this.sync_img = "assets/images/sync.gif"
  //     let onlineSendData = {
  //       // "page_no": this.p,
  //       // "order_status": 3,
  //       "terminal_id": this.terminal_id
  //     }
  //     this._posService.getAllOrderData(onlineSendData)
  //       .subscribe(
  //         async res => {
  //           if (res.status === 1) {
  //             // this.orderList = res.data.orders;
  //             // this.orderList = [];
  //             let orders = res.data;
  //             let findOrd;
  //             // orders.forEach(async element => {
  //             //   // console.log(element)
  //             //   console.log("======for start====")
  //             //   findOrd = await this.findOrderFromLocalData(element);

  //             //   if (findOrd == 0) {
  //             //     //insert into websql
  //             //     this.addOrdersIntoWebSql(element)
  //             //   } else {
  //             //     this.updateOrderIntoWebSql(element)
  //             //   }
  //             // });
  //             for (let index = 0; index < orders.length; index++) {
  //               const element = orders[index];
  //               // console.log("======for start====")
  //               findOrd = await this.findOrderFromLocalData(element);

  //               if (findOrd == 0) {
  //                 //insert into websql
  //                 this.addOrdersIntoWebSql(element)
  //               } else {
  //                 this.updateOrderIntoWebSql(element)
  //               }
  //             }
  //             this.passData.newOrderStatus(true)
  //             // console.log(findOrd)
  //             // this.spinner.hide()
  //           } else {
  //             this.orderList = []
  //             this.sync_img = "assets/images/sync_white.png";
  //             // this.spinner.hide()
  //           }
  //         },
  //         err => {
  //           console.log("error", err);
  //         })
  //   } else {
  //     this.toastr.warning("System is offline")
  //   }

  // }

  // async updateOrderIntoWebSql(ordData) {
  //   await this.sqlService.dbInstance.executeSql(`UPDATE orders SET order_status = ` + ordData.order_status + `, local_transaction_no = ` + ordData.local_transaction_no + ` where database_id = ` + ordData.id, []).then(async (data) => {
  //     this.sync_img = "assets/images/sync_white.png";
  //     // this.passData.newOrderStatus(true)
  //   })
  // }

  // async createTranscationNoTabel() {
  //   await this.sqlService.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS transcation_no(
  //     tran_no INTEGER NOT NULL,
  //     created_at TEXT
  //   )`, []);
  // }

  // async addOrdersIntoWebSql(ordData) {

  //   await this.createTranscationNoTabel()

  //   // to create transction number
  //   // var local_transaction_no = ordData.local_transaction_no;
  //   // await this.sqlService.dbInstance.executeSql(`INSERT INTO transcation_no (tran_no, created_at) VALUES(` + local_transaction_no + `,` + transdata + `)`, []).then((res) => {
  //   //   // console.log("trasndata", res)
  //   //   // local_no = localStorage.getItem('store_id') + localStorage.getItem('terminal_id') + res['insertId']
  //   //   // local_no = res['insertId']
  //   // })

  //   var new_transaction_number = 1;
  //   await this.sqlService.dbInstance.executeSql(`SELECT tran_no FROM transcation_no ORDER BY tran_no DESC LIMIT 1`, {}).then((res) => {
  //     // console.log("trasndata", res.rows)
  //     if(res.rows.length > 0){
  //       var total_transaction_number = res.rows[0].tran_no;

  //       if (total_transaction_number > 0) {
  //         new_transaction_number = total_transaction_number + 1;
  //       } else {
  //         new_transaction_number = 1;
  //       }
  //     }else{
  //       new_transaction_number = 1;
  //     }

  //   })

  //   //end to create transction number

  //   let transdata = [`'` + new Date() + `'`]
  //   let local_no;
  //   await this.sqlService.dbInstance.executeSql(`INSERT INTO transcation_no (tran_no, created_at) VALUES(` + new_transaction_number + `,` + transdata + `)`, []).then((res) => {
  //     // console.log("trasndata", res)
  //     // local_no = localStorage.getItem('store_id') + localStorage.getItem('terminal_id') + res['insertId']
  //     // local_no = res['insertId']
  //   })
  //   // let currentDate = this._datePipe.transform(ordData.created_at, 'full')
  //   // let currentDate = ordData.created_at_formated
  //   // let created_date = Date.parse(currentDate.toString());
  //   let customer_name = ordData.order_customer.name + " " + ordData.order_customer.lname;

  //   let saleNote = ordData.sale_note ? ordData.sale_note : "null";
  //   let data = [new_transaction_number, `'` + ordData.id + `'`, ordData.business_id, ordData.state_id, ordData.store_id, ordData.terminal_id,
  //     0, `'` + ordData.customer_id + `'`, `'` + customer_name + `'`, `'` + ordData.order_customer.phone + `'`, `'` + ordData.adujusted_order_id + `'`, ordData.cash_change,
  //     ordData.cash_taken, ordData.total_item, ordData.total_item_qty, ordData.total_discount,
  //     ordData.total_tax, ordData.total_price, ordData.grand_total, 0, 0, 0, ordData.discount_id, ordData.discount_rate,
  //     ordData.discount_type, 0, 0, 0, 0, ordData.order_status, `'` + ordData.cancellation_reason + `'`, 0, 0, `'` + saleNote + `'`, "null", ordData.tender_profile_id,
  //     `'` + ordData.tender_profile_name + `'`, `'` + ordData.tender_profile_image + `'`, ordData.price_override_status, ordData.created_by, ordData.created_at_formated, 1, ordData.round_off,
  //     ordData.delivery_charge];
  //   let sql = `INSERT INTO orders
  //   (local_transaction_no,database_id, business_id, state_id, store_id,terminal_id,
  //   reference_no,customer_id, customer_name,customer_phone, adujusted_order_id, cash_change,
  //   cash_taken,total_item,total_item_qty,total_discount,
  //   total_tax,total_price,grand_total,tax_id,
  //   tax_rate,tax_type, discount_id,discount_rate,
  //   discount_type,coupon_id,coupon_discount,coupon_type,
  //   shipping_cost, order_status, cancellation_reason, payment_status,
  //   paid_amount,sale_note, staff_note,tender_profile_id,
  //   tender_profile_name, tender_profile_image, price_override_status, created_by, created_at, isSync, round_off,delivery_charges) VALUES (`+ data.toString() + `)`;

  //   //insert Data into order_store_detail
  //   await this.sqlService.dbInstance.executeSql(sql, []).then((data) => {

  //     let order = data;
  //     // Success
  //     ordData.order_detail.forEach(async cartData => {
  //       let price_cmt = cartData.price_override_item_comment != "" ? cartData.price_override_item_comment : 'null';
  //       let order_cart_data = [
  //         order.insertId, cartData.pricelookup_id, cartData.pricelookup_qty, `'` + cartData.pricelookup_name + `'`,
  //         cartData.pricelookup_item_price, cartData.add_variation, cartData.price_override_item_status, cartData.price_override_item_price,
  //         cartData.price_override_item_id, `'` + price_cmt + `'`, cartData.add_addon, cartData.add_ingredient,
  //         cartData.addon_variation_combine, cartData.addon_ingredient_combine
  //       ]
  //       await this.sqlService.dbInstance.executeSql(`INSERT INTO order_store_detail(order_id, pricelookup_id, pricelookup_qty, pricelookup_name,
  //       pricelookup_item_price, add_variation, price_override_item_status, price_override_item_price,
  //       price_override_item_id, price_override_item_comment, add_addon, add_ingredient,
  //       addon_variation_combine, addon_ingredient_combine) VALUES (`+ order_cart_data.toString() + `)`, [])
  //     });

  //     let tenders = ordData.order_type;
  //     tenders.forEach(async tender => {
  //       let data_order_type = [order.insertId, tender.order_type_id, tender.order_type, `'` + tender.order_type_name + `'`, tender.order_price]
  //       await this.sqlService.dbInstance.executeSql(`INSERT INTO orders_store_order_type (order_id, order_type_id, order_type, order_type_name, order_price) VALUES (` + data_order_type.toString() + `)`, [])
  //     });
  //   })
  //   this.sync_img = "assets/images/sync_white.png";
  //   // this.passData.newOrderStatus(true)
  // }

  // async findOrderFromLocalData(order) {
  //   return new Promise(resolve => {
  //     this.sqlService.dbInstance.executeSql(`SELECT * FROM orders where database_id = ` + order.id, []).then(async (data) => {
  //       let orders = [];
  //       for (let i = 0; i < data.rows.length; i++) {
  //         orders.push(data.rows.item(i));
  //       }
  //       resolve(orders.length);
  //     });
  //   });
  // }

  showOrderFromApi(order) {
    // this.db.getItemsByOrder(order).then(data => {
    //   console.log(data)
    //   this.order_items = data;
    //   this.finalCalculation();
    // });'


    this.orderList.forEach(element => {
      if (element.id === order.id) {
        element.selectionColor = true
      } else {
        element.selectionColor = false;
      }
    });

    // this.completeOrderList.forEach(element => {
    //   if (element.id === order.id) {
    //     element.selectionColor = true
    //   } else {
    //     element.selectionColor = false;
    //   }
    // });

    this.spinner.show();
    var data = {
      order_id: order.id
    }
    this._posService.getOrderDetail(data)
      .subscribe(
        res => {
          // console.log(res);
          this.spinner.hide();
          if (res.status === 1) {
            let order_items = res.data.order_detail;
            this.cartData = res.data;
            console.log('cartData', this.cartData)
            this.cartData.order_type.forEach(element => {
              if (element.order_price > 0) {
                this.cartData.tender_type_name = element.order_type_name
              }
            });

            this.cartData.customer_name = res.data.customer_name
            this.order_items = this._dataService.getCartData(order_items);
            // this.finalCalculation();
          }
        },
        err => {
          console.log(err)
        });
  }

  finalCalculation() {
    let data = this._dataService.finalCalculation(this.order_items, this.config);
    this.grandTotal = data;
  }
  finalCalculationForCheckout() {

    let products = JSON.parse(localStorage.getItem('rawCartItems'));

    let data = this._dataService.finalCalculation(products, this.configDiscount);
    //let data = this._dataService.finalCalculation(this.cart, this.config);
    this.cart = data.products;
    this.grandTotal = data;
  }

  checkStoreTemplatePermission(item) {
    let permissions = JSON.parse(localStorage.getItem('storeReceiptData'));
    if (permissions?.length > 0) {
      let check = permissions.find(x => x.key_ == item);
      if (check) {
        if (check.status == 1 && check.value_ == '1') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    } else {
      return false;
    }
  }

  checkStoreTemplatePermissionGetValue(item) {
    let permissions = JSON.parse(localStorage.getItem('storeReceiptData'));
    if (permissions?.length > 0) {
      let check = permissions.find(x => x.key_ == item);
      if (check) {
        if (check.status == 1 && check.value_ != '') {
          return check.value_
        } else {
          return ''
        }
      } else {
        return ''
      }
    } else {
      return '';
    }
  }

  //   getAllTables() {
  //   let query = `SELECT * FROM store_tables`;
  //   if (this.tableStatusFilter != 3) {
  //     query += " where booking_status =" + this.tableStatusFilter
  //   }

  //   this.sqlService.dbInstance.executeSql(query, []).then(async (data) => {
  //     let list = [];
  //     for (let i = 0; i < data.rows.length; i++) {
  //       list.push(data.rows.item(i));
  //     }
  //     this.tables = list;
  //     console.log(this.tables)
  //   })
  // }

}
